import "../../styles/leaveMore.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import purpleIcon from "../../assets/images/user-group-purple.png";
import redIcon from "../../assets/images/user-group-red.png";
import blueIcon from "../../assets/images/user-group-blue.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import DocumentPopup from "../../components/Reusable/DocumentPopup";
import formatDate from "../../components/Reusable/dateUtils";
import DownloadLeaveDetails from "./DownloadLeaveDetails";

const EmployeeLeaveMoreDetails = ({
  setShowEmployeeLeaveDetails,
  employeeData,
  selectedCompany,
}) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [leaveCount, setLeaveCount] = useState([]);

  const fetchLeaveCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/${employeeData.user?.employee_information_id}/leaveCount`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setLeaveCount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLeaveCount();
  }, []);

  const empCountry = employeeData?.user?.roles[0]?.company[0]?.country;

  const leaveTypes = [...new Set(leaveCount.map((leave) => leave.LeaveType))];

  const totalLeaves = leaveCount
    .filter(
      (leave) =>
        leave.country === empCountry && leaveTypes.includes(leave.LeaveType)
    )
    .reduce((total, leave) => total + leave.count, 0);

  const totalLeavesTaken = leaveCount
    .filter(
      (leave) =>
        leave.country === empCountry && leaveTypes.includes(leave.LeaveType)
    )
    .reduce((total, leave) => total + leave.leave_days_taken, 0);

  const availableLeaves = totalLeaves - totalLeavesTaken;

  const handleOpenDocumentPopup = () => {
    setShowDocumentPopup(true);
  };

  console.log("EL More Details", employeeData);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="relative w-full max-w-5xl mx-4 h-[95vh] bg-white rounded-lg shadow-lg overflow-y-scroll">
        <div className="flex justify-between items-center px-4 py-3 bg-gray-100 border-b">
          <h3 className="text-xl font-medium text-[#505050]">
            Employee Leave More Details
          </h3>
          <button
            type="button"
            className="bg-[#F3F4F6] hover:scale-110"
            onClick={() => setShowEmployeeLeaveDetails(null)}
          >
            ❌
          </button>
        </div>

        <div className="flex flex-col flex-grow py-2 px-12">
          <div className="emp_more-info">
            <div className="title">
              <div className="emp_image">
                <img src={employeeData?.user?.profile_picture} alt="Employee" />
              </div>
              <div className="info_emp-details">
                <h4>{employeeData?.user?.employee_full_name}</h4>
                <p>{employeeData?.user?.roles[0]?.role_name}</p>
              </div>
            </div>
            <div className="emp_edit-leaves">
              <div className="description total_leaves">
                <div className="icon purple">
                  <img src={purpleIcon} alt="icon" />
                </div>
                <div>
                  <p>Total Leaves</p>
                  <h3>
                    {totalLeaves} <span>Yearly</span>
                  </h3>
                </div>
                <ul>
                  {leaveCount
                    .filter((leave) => leave.country === empCountry)
                    .map((leave, index) => (
                      <li key={index}>
                        {leave.LeaveType} {leave.count}
                      </li>
                    ))}
                </ul>
              </div>

              <div className="description leaves_taken">
                <div className="icon red">
                  <img src={redIcon} alt="icon" />
                </div>
                <div>
                  <p>Leaves Taken</p>
                  <h3>
                    {totalLeavesTaken} <span>Yearly</span>
                  </h3>
                </div>
                <ul>
                  {leaveCount
                    .filter((leave) => leave.country === empCountry)
                    .map((leave, index) => (
                      <li key={index}>
                        {leave.LeaveType} {leave.leave_days_taken}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="description available_leaves">
                <div className="icon blue">
                  <img src={blueIcon} alt="icon" />
                </div>
                <div>
                  <p>Available Leaves</p>
                  <h3>
                    {availableLeaves} <span>Yearly</span>
                  </h3>
                </div>
                <ul>
                  {leaveCount
                    .filter((leave) => leave.country === empCountry)
                    .map((leave, index) => (
                      <li key={index}>
                        {leave.LeaveType} {leave.count - leave.leave_days_taken}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="info_section">
            <div className="employee_id-date">
              <p>Employee ID:</p>
              <h6>{employeeData?.user?.employee_id}</h6>
              <p>Applied Date:</p>
              <h6>{formatDate(employeeData?.apply_date)}</h6>
            </div>
            <div>
              <DownloadLeaveDetails
                employeeData={employeeData}
                leaveCount={leaveCount}
                empCountry={empCountry}
              />
            </div>
            <div>
              {employeeData?.user?.employee_information_id && (
                <Link
                  to={`/employeeleavehistory?employeeInformationId=${employeeData?.user?.employee_information_id}`}
                >
                  <p className="leave_history" style={{ cursor: "pointer" }}>
                    <MdOutlineRemoveRedEye className="text-3xl"/>
                    View Leave History
                  </p>
                </Link>
              )}
            </div>
          </div>
          <div className="leave_status">
            <div className="leave_desc">
              <p>Leave Start Date</p>
              <span>{formatDate(employeeData?.leave_from)}</span>
            </div>
            <div className="leave_desc">
              <p>Leave End Date</p>
              <span>{formatDate(employeeData?.leave_to)}</span>
            </div>
            <div className="leave_desc">
              <p>Leave Type</p>
              <span>{employeeData?.leaveType}</span>
            </div>
            <div className="leave_desc">
              <p>No. of Days</p>
              <span>{employeeData?.days_count}</span>
            </div>
            <div className="leave_desc">
              <p>View Document</p>
              <button
                onClick={handleOpenDocumentPopup}
                className="bg-green-600 text-xs text-white py-1.5 px-3 rounded"
              >
                Click Here
              </button>
            </div>
          </div>

          <div className="leave_reason">
            <label>Reason for leave</label>
            <p>{employeeData?.purpose}</p>
          </div>

          <div>
            <p className="my-2 text-[#505050] text-base font-semibold">
              Approved Managers List
            </p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Desgination</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {employeeData?.approving_managers_status.map((manager) => (
                  <tr key={manager?.index}>
                    <td>{manager?.emp_id}</td>
                    <td>{manager?.manager_name}</td>
                    <td>{manager?.role_name}</td>
                    <td>{manager?.status}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    Total Approvers: {employeeData?.approving_manager_count}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="leave_remarks">
            <label>Remarks/Notes</label>
            <p>{employeeData?.deny_reason}</p>
          </div>
        </div>

        <div className="px-4 py-2 bg-gray-100 border-t flex justify-end">
          <button
            onClick={() => setShowEmployeeLeaveDetails(null)}
            className="bg-red-500 !important text-white py-2 px-3 rounded text-sm hover:bg-red-600 transition-all"
          >
            Close
          </button>
        </div>
        {showDocumentPopup && (
          <DocumentPopup
            documentUrl={employeeData.document_url}
            onClose={() => setShowDocumentPopup(false)}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeLeaveMoreDetails;
