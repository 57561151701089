import { useLocation, useNavigate, useParams } from "react-router-dom";
import { calculateNetSalary, getLastDayOfMonth } from "./calculateNetSalary";
import { useRef } from "react";
import { defaultCompanyData } from "./defaultCompanyData";
import {
  getEmployeeDetails,
  getPayslipDetails,
  usePrintHandler,
} from "./payslipUtils.js";
import PayslipTable from "./PayslipTable";
import EmployeeDetailRow from "./EmployeeDetailRow";

const Payslip = () => {
  const { id } = useParams();
  const location = useLocation();
  const salaryDetails = location.state?.salaryDetails;
  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = usePrintHandler(componentRef);

  if (!salaryDetails || !salaryDetails.length) {
    return (
      <div className="max-w-3xl mx-auto p-8 bg-white shadow-lg">
        <p className="text-center text-gray-600">
          No salary details found for employee {id}
        </p>
      </div>
    );
  }

  const colorMap = {
    ttf: "bg-ttf",
    scalex: "bg-scalex",
    rms: "bg-rms",
    greenapple: "bg-greenapple",
    bluebird: "bg-bluebird",
  };

  const defaultData =
    defaultCompanyData.find(
      (company) => company.companyName === salaryDetails[0]?.company
    ) || defaultCompanyData[0];

  const data = { ...defaultData, ...salaryDetails[0] };
  const netSalaryDetails = data.payslip
    ? calculateNetSalary(data.payslip)
    : {
        allowances: "0.00",
        deductions: "0.00",
        totalEarnings: "0.00",
        totalAdditions: "0.00",
        netSalary: "0.00",
      };

  const headerClass = colorMap[defaultData.colorCode] || "bg-gray-600";
  const sections = getPayslipDetails(data, netSalaryDetails);
  const employeeDetails = getEmployeeDetails(data);

  return (
    <div>
      <div className="flex items-center justify-between py-8 px-16">
        <button
          onClick={() => navigate("/employeesalary")}
          className="text-2xl text-theme-color flex"
        >
          <span>
            <i className="ri-arrow-left-s-line"></i>
          </span>
          BACK
        </button>
        <button
          onClick={handlePrint}
          className="text-2xl text-theme-color flex"
        >
          <i className="fa-solid fa-print"></i>
        </button>
      </div>
      <div
        ref={componentRef}
        className="payslip-content max-w-3xl mx-auto p-8 bg-white shadow-lg relative"
      >
        {/* Watermark Element */}
        <div
          className="hidden print:block"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%) rotate(-45deg)",
            opacity: "0.1",
            fontSize: "100px",
            color: "#000000",
            zIndex: 9999,
            pointerEvents: "none",
          }}
        >
          {data.prefix}
        </div>
        <div className="flex justify-between items-center">
          <div>
            <img
              src={defaultData.companyLogo}
              alt="company Logo"
              className="w-20 h-auto"
            />
          </div>
          <div>
            <p className="text-xs text-gray-600">
              {defaultData.address.map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </p>
            <p className="text-xs text-gray-600 mb-4">
              {defaultData.address2.map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </p>
          </div>
        </div>

        <div className="text-center px-8 pb-4">
          <h2 className="text-lg font-semibold">
            PAYSLIP FOR THE MONTH OF {data?.payslip?.month}{" "}
            {data?.payslip?.year}
          </h2>
        </div>

        <div className="custom-table mb-4">
          <table className="table-auto border-collapse border border-gray-300 w-full text-sm mb-6">
            <tbody>
              {employeeDetails.map((detail, index) => (
                <EmployeeDetailRow key={index} {...detail} />
              ))}
            </tbody>
          </table>
        </div>

        <PayslipTable
          title="Particulars"
          what="Salary"
          total="Total"
          remark="Remarks"
          sections={sections.earnings}
          headerClass={headerClass}
        />

        <PayslipTable
          title="Deductions"
          sections={sections.deductions}
          headerClass={headerClass}
        />

        <PayslipTable
          title="Additions"
          sections={sections.additions}
          headerClass={headerClass}
        />

        <div className="payslip-table">
          <table className="table-auto border-collapse border border-gray-300 w-full text-sm mb-6">
            <tbody>
              <tr>
                <td className="text-base font-bold text-start border border-slate-300 p-2">
                  Net Salary
                </td>
                <td className="text-base font-bold text-end border border-slate-300 p-2">
                  {netSalaryDetails.netSalary}
                </td>
                <td className="text-center border border-slate-300 p-2">-</td>
                <td className="text-center border border-slate-300 p-2">-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-8 text-sm text-right">
          <div className="text-xs">
            Prepared by:{" "}
            <span className="font-bold text-xs">Francis Thomas</span>
          </div>
          <div className="text-xs">
            Date:{" "}
            <span className="font-bold text-xs">
              {getLastDayOfMonth(data?.payslip?.month, data?.payslip?.year)}-
              {data?.payslip?.month}-{data?.payslip?.year}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payslip;
