import "../../styles/leaveMore.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import purpleIcon from "../../assets/images/user-group-purple.png";
import redIcon from "../../assets/images/user-group-red.png";
import blueIcon from "../../assets/images/user-group-blue.png";
import Popup from "../../components/Reusable/Popup";
import DocumentPopup from "../../components/Reusable/DocumentPopup";

const UpdateLeaveStatus = ({
  setShowUpdateLeaveDetails,
  employeeData,
  setSelectedEmployeeData,
  getEmployeeMonthWiseLeave,
}) => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [leaveCount, setLeaveCount] = useState([]);

  const fetchLeaveCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/${employeeData.user?.employee_information_id}/leaveCount`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setLeaveCount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLeaveCount();
  }, []);

  const empCountry = employeeData?.user?.roles[0]?.company[0]?.country;

  const leaveTypes = [...new Set(leaveCount.map((leave) => leave.LeaveType))];

  const totalLeaves = leaveCount
    .filter(
      (leave) =>
        leave.country === empCountry && leaveTypes.includes(leave.LeaveType)
    )
    .reduce((total, leave) => total + leave.count, 0);

  const totalLeavesTaken = leaveCount
    .filter(
      (leave) =>
        leave.country === empCountry && leaveTypes.includes(leave.LeaveType)
    )
    .reduce((total, leave) => total + leave.leave_days_taken, 0);

  const availableLeaves = totalLeaves - totalLeavesTaken;

  const handleApprove = () => {
    setShowApprovePopup(true);
  };

  const handleDecline = () => {
    setShowDeclinePopup(true);
  };

  const handleApproveYesButton = async (leaveId) => {
    let apiUrl = "";
    let approvalType = "";
    let approvalField = "";
    let forwardType = "";

    const hasViewManagedLeaveHistoryPermission = permissions.some(
      (permission) => permission.name === "view_managed_leave_history"
    );
    const hasApproveLeavePermission = permissions.some(
      (permission) => permission.name === "approve_leave"
    );
    const hasViewAllLeaveHistoryPermission = permissions.some(
      (permission) => permission.name === "view_all_leave_history"
    );
    const hasApproveLeaveHRPermission = permissions.some(
      (permission) => permission.name === "approve_leave_hr"
    );

    if (hasViewManagedLeaveHistoryPermission && hasApproveLeavePermission) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/managerLeaveApproval`;
      approvalType = "is_approved";
      forwardType = "forward";
      approvalField = "deny_reason";
    } else if (
      hasViewAllLeaveHistoryPermission &&
      hasApproveLeaveHRPermission
    ) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/HrLeaveApproval`;
      approvalType = "is_hr_approved";
      approvalField = "deny_reason";
    } else {
      console.log("User does not have required permissions.");
      return;
    }

    try {
      const response = await axios.put(
        apiUrl,
        {
          leave_id: leaveId,
          [approvalType]: 1,
          [forwardType]: 1,
          [approvalField]: "Because",
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;
      setShowApprovePopup(false);
      fetchLeaveCount();
      getEmployeeMonthWiseLeave();

      if (data.success) {
        setSelectedEmployeeData(null);
        setIsApproved(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeclineYesButton = async (leaveId) => {
    let apiUrl = "";
    let approvalType = "";
    let forwardType = "";
    let approvalField = "";

    const hasViewManagedLeaveHistoryPermission = permissions.some(
      (permission) => permission.name === "view_managed_leave_history"
    );
    const hasApproveLeaveManagerPermission = permissions.some(
      (permission) => permission.name === "approve_leave"
    );
    const hasViewAllLeaveHistoryPermission = permissions.some(
      (permission) => permission.name === "view_all_leave_history"
    );
    const hasApproveLeaveHRPermission = permissions.some(
      (permission) => permission.name === "approve_leave_hr"
    );

    if (
      hasViewManagedLeaveHistoryPermission &&
      hasApproveLeaveManagerPermission
    ) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/managerLeaveApproval`;
      approvalType = "is_approved";
      forwardType = "forward";
      approvalField = "deny_reason";
    } else if (
      hasViewAllLeaveHistoryPermission &&
      hasApproveLeaveHRPermission
    ) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/HrLeaveApproval`;
      approvalType = "is_hr_approved";
      approvalField = "deny_reason";
    } else {
      console.log("User does not have required permissions.");
      return;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        leave_id: leaveId,
        [approvalType]: 0,
        [forwardType]: 0,
        [approvalField]: "Approved then declined",
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      setShowDeclinePopup(false);
      fetchLeaveCount();
      getEmployeeMonthWiseLeave();

      if (data.success) {
        setSelectedEmployeeData(null);
        setIsApproved(true);
      } else {
        console.log("Decline failed:", data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveNoButton = () => {
    setShowApprovePopup(false);
    // setSelectedEmployeeData(null);
  };

  const handleDeclineNoButton = () => {
    setShowDeclinePopup(false);
    // setSelectedEmployeeData(null);
  };

  const handleOpenDocumentPopup = () => {
    setShowDocumentPopup(true);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="relative w-full max-w-5xl mx-4 h-[95vh] bg-white rounded-lg shadow-lg overflow-x-hidden overflow-y-scroll">
        <div className="flex justify-between items-center px-4 py-3 bg-gray-100 border-b">
          <h3 className="text-xl font-medium text-[#505050]">
            Update Leave Status
          </h3>
          <button
            type="button"
            className="bg-[#F3F4F6] hover:scale-110"
            onClick={() => setShowUpdateLeaveDetails(null)}
          >
            ❌
          </button>
        </div>

        <div className="emp_more-details px-6">
          <div className="emp_more-info">
            <div className="title">
              <div className="emp_image">
                <img src={employeeData.user?.profile_picture} alt="Employee" />
              </div>
              <div className="info_emp-details">
                <h4>{employeeData.user?.employee_full_name}</h4>
                <p>{employeeData.user?.roles[0]?.role_name}</p>
              </div>
            </div>
            <div className="emp_edit-leaves">
              <div className="description total_leaves">
                <div className="icon purple">
                  <img src={purpleIcon} alt="icon" />
                </div>
                <div>
                  <p>Total Leaves</p>
                  <h3>
                    {totalLeaves} <span>Yearly</span>
                  </h3>
                </div>
                <ul>
                  {leaveCount
                    .filter((leave) => leave.country === empCountry)
                    .map((leave, index) => (
                      <li key={index}>
                        {leave.LeaveType} {leave.count}
                      </li>
                    ))}
                </ul>
              </div>

              <div className="description leaves_taken">
                <div className="icon red">
                  <img src={redIcon} alt="icon" />
                </div>
                <div>
                  <p>Leaves Taken</p>
                  <h3>
                    {totalLeavesTaken} <span>Yearly</span>
                  </h3>
                </div>
                <ul>
                  {leaveCount
                    .filter((leave) => leave.country === empCountry)
                    .map((leave, index) => (
                      <li key={index}>
                        {leave.LeaveType} {leave.leave_days_taken}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="description available_leaves">
                <div className="icon blue">
                  <img src={blueIcon} alt="icon" />
                </div>
                <div>
                  <p>Available Leaves</p>
                  <h3>
                    {availableLeaves} <span>Yearly</span>
                  </h3>
                </div>
                <ul>
                  {leaveCount
                    .filter((leave) => leave.country === empCountry)
                    .map((leave, index) => (
                      <li key={index}>
                        {leave.LeaveType} {leave.count - leave.leave_days_taken}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="info_section">
            <div className="employee_id-date">
              <p>Employee ID:</p>
              <h6>{employeeData?.user?.employee_id}</h6>
              <p>Applied Date:</p>
              <h6>{employeeData?.apply_date}</h6>
            </div>
            <div>
              {employeeData.user?.employee_information_id && (
                <Link
                  to={`/employeeleavehistory?employeeInformationId=${employeeData.user?.employee_information_id}`}
                >
                  <p className="leave_history" style={{ cursor: "pointer" }}>
                    View Leave History
                  </p>
                </Link>
              )}
            </div>
          </div>
          <div className="flex flex-start gap-5 bg-[#f9fafe] p-2.5 mt-0">
            <div className="leave_desc">
              <p>Leave Start Date</p>
              <span>{employeeData?.leave_from}</span>
            </div>
            <div className="leave_desc">
              <p>Leave End Date</p>
              <span>{employeeData?.leave_to}</span>
            </div>
            <div className="leave_desc">
              <p>Leave Type</p>
              <span>{employeeData?.leaveType}</span>
            </div>
            <div className="leave_desc">
              <p>No. of Days</p>
              <span>{employeeData?.days_count}</span>
            </div>
            <div className="leave_desc">
              <p>
                <span>
                  {employeeData?.is_hr_approved || employeeData?.is_approved
                    ? "Approved Manager"
                    : employeeData?.is_hr_approved === 0 ||
                      employeeData?.is_approved === 0
                    ? "Rejected Manager"
                    : "Authorized Manager"}
                </span>
              </p>
              <span>
                {employeeData?.is_hr_approved || employeeData?.is_approved
                  ? employeeData?.approving_manager
                  : employeeData?.is_hr_approved === 0 ||
                    employeeData?.is_approved === 0
                  ? employeeData?.rejecting_manager
                  : null}
              </span>
            </div>
          </div>

          <div className="leave_reason">
            <label>Reason for leave</label>
            <p>{employeeData?.purpose}</p>
          </div>
          <div className="view_document" onClick={handleOpenDocumentPopup}>
            View Document &gt;
          </div>
          <div className="leave_remarks">
            <label htmlFor="">Remarks/Notes</label>
            <textarea name="remarks" id="remarks" rows="2"></textarea>
          </div>

          {showApprovePopup && (
            <Popup
              title="Approve Leave Request"
              message="Would you please confirm that you are approving this request?"
              onConfirm={handleApproveYesButton}
              onCancel={handleApproveNoButton}
              leaveId={employeeData.leave_id}
            />
          )}

          {showDeclinePopup && (
            <Popup
              title="Decline Leave Request"
              message="Are you sure that you are declining this request?"
              onConfirm={handleDeclineYesButton}
              onCancel={handleDeclineNoButton}
              leaveId={employeeData.leave_id}
            />
          )}
          {showDocumentPopup && (
            <DocumentPopup
              documentUrl={employeeData.document_url}
              onClose={() => setShowDocumentPopup(false)}
            />
          )}
        </div>
        <div className="px-4 py-2 bg-gray-100 border-t flex justify-end">
          {!employeeData.is_approved && !employeeData.is_hr_approved ? (
            <button
              type="button"
              onClick={handleApprove}
              className="bg-green-600 !important text-white py-2 px-3 rounded text-sm hover:bg-green-700 transition-all"
            >
              Approve
            </button>
          ) : (
            <button
              type="button"
              onClick={handleDecline}
              className="bg-red-500 !important text-white py-2 px-3 rounded text-sm hover:bg-red-600 transition-all"
            >
              Decline
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateLeaveStatus;
