import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../components/MultiStep/multiStep.css";
import Modal from "react-modal";
import axios from "axios";
import { Container, Row, Col, Card } from "react-bootstrap";
import MultiStepProgressBar from "../components/MultiStep/MultiStepProgressBar";
import MultiStepForm from "../components/MultiStep/MultiStepForm";
Modal.setAppElement("#root");

function AddEmployeeBar() {
  const [index, setIndex] = useState(1);
  const [error, setError] = useState(null);
  const [empData, setEmpData] = useState({
    employeeId: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "Male",
    marital_status: "0",
    personal_mail_id: "",
    work_phone_number: "",
    personal_phone_number: "",
    emergency_contact_name: "",
    emergency_contact_relation: "",
    emergency_contact_number: "",
    emergency_contact_email: "",
    nationality: "",
    language: [
      {
        name: "",
        read: false,
        write: false,
        speak: false,
        is_approved: 1,
      },
    ],
    address: [
      {
        street_address: "",
        state: "",
        country: "",
        is_permanent: 1,
        is_approved: 1,
        latitude: "22.2222",
        longitude: "22.2222",
      },
    ],
    companyName: "",
    department: "",
    joining_date: "",
    reportingManager: "",
    designation: "",
    access_card_expiry_date: "",
    work_hours: "",
    qualification: [
      {
        qualification_name: "",
        field_of_study: "",
        university_name: "",
        country: "",
        course_name: "",
        passout_year: "",
        is_approved: 1,
      },
    ],
    experiences: [
      {
        previous_worked_firm: "",
        organization_address: "",
        organization_city: "",
        organization_country: "",
        designation: "",
        date_of_joining: "",
        relieving_date: "",
        experience_in_months: "",
        is_approved: 1,
        references: [
          {
            reference_person: "",
            reference_designation: "",
            reference_mobile_no: "",
            reference_email: "",
          },
        ],
      },
    ],
    documents: [
      {
        name: "",
        document_number: "",
        expiry_date: "",
        file: "",
        country_of_issue: "",
        is_approved: 1,
      },
    ],
  });

  const errorModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#f8d7da",
      borderRadius: "8px",
      maxWidth: "400px",
      textAlign: "center",
      padding: "20px",
      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
  };

  const handleCloseModal = () => {
    setError(null);
  };

  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  const previousButton = () => {
    setIndex((prevIndex) => prevIndex - 1);
  };

  const nextButton = async (_empData) => {
    if (index <= 6) {
      switch (index) {
        // CASE 1 BASIC INFORMATION
        case 1:
          var myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var requestBody = JSON.stringify(_empData);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: requestBody,
            redirect: "follow",
          };

          try {
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/employees/information`,
              requestOptions
            );

            const result = await response.json();

            if (result.errors) {
              const errorMessages = Object.values(result.errors)
                .flat()
                .join(", ");
              setError(
                errorMessages ||
                  "An error occurred while submitting basic information"
              );
              return;
            }

            setIndex((prevIndex) => prevIndex + 1);
            setEmpData({ ..._empData, id: result.id });

            var languagesData = {
              id: result.id,
              languages: _empData.language.map((field) => ({
                language_name: field.name,
                read: field.read,
                write: field.write,
                speak: field.speak,
                is_approved: true,
              })),
            };

            var requestOptions2 = {
              method: "POST",
              headers: myHeaders,
              body: JSON.stringify(languagesData),
              redirect: "follow",
            };

            const languagesResponse = await fetch(
              `${process.env.REACT_APP_BASE_URL}/employees/languages`,
              requestOptions2
            );

            const languagesResult = await languagesResponse.json();

            // if (languagesResult.errors) {
            //   const languageErrorMessages = Object.values(
            //     languagesResult.errors
            //   )
            //     .flat()
            //     .join(", ");
            //   setError(
            //     languageErrorMessages ||
            //       "An error occurred while submitting languages"
            //   );
            // }
          } catch (error) {
            console.error("Error:", error);
            // setError(error.message || "An unexpected error occurred");
          }
          break;

        // CASE 2 ADDRESS
        case 2:
          console.log(index, empData);
          var myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          myHeaders.append("Content-Type", "application/json");

          var requestBody = JSON.stringify({
            id: _empData.id,
            addresses: _empData.address,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: requestBody,
            redirect: "follow",
          };

          fetch(
            `${process.env.REACT_APP_BASE_URL}/employees/addresses`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              console.log("address", result.id);
              if (!result.hasOwnProperty("errors")) {
                if (result.id) {
                  setIndex((prevIndex) => prevIndex + 1);
                  setEmpData({ ..._empData, id: result.id });
                } else {
                  setError("Error: result.id is not present");
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
              setError(error);
            });
          break;

        // CASE 3 JOINING DETAILS
        case 3:
          var myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var raw = JSON.stringify({
            id: _empData.id,
            joining_date: _empData.joining_date,
            access_card_expiry_date: _empData.access_card_expiry_date,
            reporting_manager_id: _empData.reportingManager,
            work_hours: _empData.work_hours,
            role_id: _empData.designation,
            company_id: _empData.companyName,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          try {
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/employees/joiningDetails`,
              requestOptions
            );

            const result = await response.json();

            if (result.errors) {
              const errorMessages = Object.values(result.errors)
                .flat()
                .join(", ");
              setError(
                errorMessages ||
                  "An error occurred while submitting joining details"
              );
              return;
            }

            if (!result.id) {
              setError(
                "Error: Employee ID is missing after submitting joining details"
              );
              return;
            }

            setIndex((prevIndex) => prevIndex + 1);
            setEmpData({ ..._empData, id: result.id });
          } catch (error) {
            console.error("Error:", error);
            setError(
              error.message ||
                "An unexpected error occurred while submitting joining details"
            );
          }
          break;

        // CASE 4 QUALIFICATIONS
        case 4:
          var myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          console.log("_empData.qualification", _empData.qualification);
          var raw = JSON.stringify({
            id: _empData.id,
            qualifications: _empData.qualification.map((q) => ({
              education_level_id: q.qualification_name,
              field_of_studies_id: q.field_of_study,
              university_name: q.university_name,
              country: q.country,
              major_of_studies_id: q.course_name,
              passout_year: q.passout_year,
              is_approved: 1,
            })),
          });

          console.log("Qualifications raw", raw);
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(
            `${process.env.REACT_APP_BASE_URL}/employees/qualifications`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              console.log("qualifications", result.id);
              if (!result.hasOwnProperty("errors")) {
                if (result.id) {
                  setIndex((prevIndex) => prevIndex + 1);
                  setEmpData({ ..._empData, id: result.id });
                } else {
                  setError("Error: result.id is not present");
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
              setError(error);
            });
          break;

        // CASE 5 EXPERIENCE AND REFERENCE
        case 5:
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var raw = JSON.stringify({
            id: _empData.id,
            experiences: _empData.experiences,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(
            `${process.env.REACT_APP_BASE_URL}/employees/experiences`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (!result.hasOwnProperty("errors")) {
                if (result.id) {
                  setIndex((prevIndex) => prevIndex + 1);
                  setEmpData({ ..._empData, id: result.id });
                } else {
                  setError("Error: result.id is not present");
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
              setError(error);
            });

          break;

        // CASE 6 DOCUMENTS
        case 6:
          var myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Content-Type", "multipart/form-data");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          const documentsFormdata = new FormData();

          documentsFormdata.append("id", _empData.id);

          _empData.documents.forEach((document, index) => {
            documentsFormdata.append(
              `documents[${index}][name]`,
              document.name
            );
            documentsFormdata.append(
              `documents[${index}][document_number]`,
              document.document_number
            );
            documentsFormdata.append(
              `documents[${index}][file]`,
              document.file
            );
            documentsFormdata.append(
              `documents[${index}][expiry_date]`,
              document.expiry_date
            );
            documentsFormdata.append(
              `documents[${index}][country_of_issue]`,
              document.country_of_issue
            );
            documentsFormdata.append(
              `documents[${index}][is_approved]`,
              document.is_approved
            );
          });

          const url = `${process.env.REACT_APP_BASE_URL}/employees/documents`;
          axios
            .post(url, documentsFormdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((response) => {
              if (!response.hasOwnProperty("errors")) {
                setEmpData({ ..._empData, id: response.id });
                alert("Employee Added Successfully");
                navigate(`/employeedirectory/${_empData.id}`);
              }
            })
            .catch((error) => {
              console.log("Show me the error", error);
              setError(error);
            });
          break;

        default:
          break;
      }
    }
  };

  const skipButton = () => {
    setIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <div className="app">
      <div className="employee_form-header">
        <h4 className="text-2xl font-medium">Add New Employee</h4>
      </div>
      <Container className="h-100">
        <Row className="text-center mb-4">
          <Col>
            <p>
              <b>Basic Information</b>
            </p>
          </Col>
          <Col>
            <p>
              <b>Address Details</b>
            </p>
          </Col>
          <Col>
            <p>
              <b>Joining Details</b>
            </p>
          </Col>
          <Col>
            <p>
              <b>Qualification</b>
            </p>
          </Col>
          <Col>
            <p>
              <b>Experience</b>
            </p>
          </Col>
          <Col>
            <p>
              <b>Documents</b>
            </p>
          </Col>
        </Row>
        <Row className="h-100">
          <Col className="align-self-center d-flex justify-content-center">
            <MultiStepProgressBar step={index} />
          </Col>
        </Row>
        <Row>
          <Card className="mt-4 card__items">
            <Card.Body>
              <MultiStepForm
                step={index}
                empData={empData}
                setEmpData={setEmpData}
                next={nextButton}
                previousButton={previousButton}
                skip={skipButton}
              />
            </Card.Body>
          </Card>
        </Row>
      </Container>
      <Modal
        isOpen={!!error}
        onRequestClose={() => setError(null)}
        contentLabel="Error"
        style={errorModalStyles}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              color: "#721c24",
              marginBottom: "15px",
              fontSize: "18px",
            }}
          >
            Error
          </h5>
          <p
            style={{
              color: "#721c24",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            {error}
          </p>
          <button
            onClick={handleCloseModal}
            style={{
              backgroundColor: "#dc3545",
              color: "white",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#c82333")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#dc3545")}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default AddEmployeeBar;
