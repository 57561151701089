import "../../styles/employeeSalary.css";
import { useNavigate } from "react-router-dom";
import EmployeeSalaryTable from "./EmployeeSalaryTable";


const EmployeeSalary = () => {
  const navigate = useNavigate();
  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Employee Salary</h1>

        <div className="add__bulk-salary">
          <button onClick={() => navigate("/bulkuploadsalarylist")}>
            Bulk Upload Employee Salaries +
          </button>
        </div>
      </div>
     
      <div>
        <EmployeeSalaryTable />
      </div>
    </div>
  );
};

export default EmployeeSalary;
