import "../styles/loginPage.css";
import logo from "../assets/images/LoginLogo.png";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const [userLoginId, setUserLoginId] = useState("");
  const [userLoginPassword, setUserLoginPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(
    localStorage.getItem("accessToken") ? true : false
  );
  const [loginEmployeeData, setLoginEmployeeData] = useState([]);
  const [idErrorMessage, setIdErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberedUserInfo, setRememberedUserInfo] = useState({
    employeeId: "",
    password: "",
  });

  useEffect(() => {
    const rememberedUserId = localStorage.getItem("rememberedUserId");
    const rememberedUserPassword = localStorage.getItem(
      "rememberedUserPassword"
    );

    if (localStorage.getItem("accessToken")) {
      setLoginSuccess(true);
    }

    if (rememberedUserId && rememberedUserPassword) {
      setUserLoginId(rememberedUserId);
      setUserLoginPassword(rememberedUserPassword);
    }
  }, []);

  const handleLogin = () => {
    const timestamp = new Date().getTime();

    if (!userLoginId.trim()) {
      setIdErrorMessage("Enter your Employee ID");
      return;
    }

    if (!userLoginPassword) {
      setPasswordErrorMessage("Enter your Password");
      return;
    }

    const formdata = new FormData();
    formdata.append("employee_id", userLoginId);
    formdata.append("password", userLoginPassword);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/login?timestamp=${timestamp}`,
        formdata,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => response.data)
      .then((result) => {
        console.log("API Response:", result);
        setLoginEmployeeData(result);
        if (result.status === true) {
          const accessToken = result.token;
          const refreshToken = result.refreshToken;
          console.log(result.permissions);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("userData", JSON.stringify(result));
          localStorage.setItem("selectedCompanyId", JSON.stringify(result.user[0]?.roles[0]?.company[0]?.id));
          const userPermissions = result.permissions;
          localStorage.setItem(
            "userPermissions",
            JSON.stringify(userPermissions)
          );

          if (rememberMe) {
            setRememberedUserInfo({
              employeeId: userLoginId,
              password: userLoginPassword,
            });
          }

          setLoginSuccess(true);
          navigate("/dashboard");
        } else {
          if (result.message === "Employee ID doesn't exist") {
            setIdErrorMessage(result.message);
            setPasswordErrorMessage("");
          } else if (
            result.message === "Password doesn't match with Employee ID"
          ) {
            setIdErrorMessage("");
            setPasswordErrorMessage(result.message);
          } else {
            setIdErrorMessage("Either Employee ID or Password is Wrong");
            setPasswordErrorMessage("Either Employee ID or Password is Wrong");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Either Employee ID or Password is Wrong");
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="login_page-container">
      <div className="login_page-left_side">
        <img src={logo} alt="logo" style={{ width: "200px", height: "75px" }} />
        <div className="title">
          <h1>Welcome back!</h1>
          <p>Please enter your user name and password to access your account</p>
        </div>
      </div>
      <div className="login_page-right_side">
        {errorMessage ? <p className="error-main">{errorMessage}</p> : ""}
        <div className="login_details">
          <h2>Login</h2>
          <p>Employee ID</p>
          <input
            type="text"
            name="employee_id"
            id="employee_id"
            placeholder={idErrorMessage ? idErrorMessage : "Employee ID"}
            value={userLoginId}
            onChange={(e) => {
              const inputValue = e.target.value.toUpperCase();
              setUserLoginId(inputValue);
              setIdErrorMessage("");
              setErrorMessage("");
            }}
            className={idErrorMessage ? "error-input" : ""}
          />

          <p htmlFor="password">Password</p>
          <input
            type="password"
            name="password"
            id="password"
            placeholder={
              passwordErrorMessage ? passwordErrorMessage : "Password"
            }
            value={userLoginPassword}
            onChange={(e) => {
              setUserLoginPassword(e.target.value);
              setPasswordErrorMessage("");
              setErrorMessage("");
            }}
            className={passwordErrorMessage ? "error-input" : ""}
            onKeyDown={handleKeyPress}
          />
          <div className="password_reminder">
            <div className="remember">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="checkbox">Remember</label>
            </div>
            <div className="forgot_password">
              <p>Forgot Password ?</p>
            </div>
          </div>
          <button className="login_button" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
