export const calculateNetSalary = (payslip) => {
  const allowances = [
    payslip.basic_salary,
    payslip.hra,
    payslip.car_allowance,
    payslip.transport_allowance,
    payslip.others_addition,
    payslip.leave_encashment,
    payslip.overtime,
    payslip.compo_off,
    payslip.commission,
    payslip.other_allowance,
  ].reduce((sum, value) => sum + parseFloat(value || 0), 0);

  const perDaySalary = parseFloat(payslip.basic_salary || 0) / 30;

  const deductions = [
    payslip.unpaid_leaves * perDaySalary,
    payslip.car_deduction,
    payslip.fines,
    payslip.salik,
    payslip.telephone,
    payslip.loan_taken,
    payslip.salary_advance,
    payslip.transport_extra_usage,
    payslip.others_deduction,
  ].reduce((sum, value) => sum + parseFloat(value || 0), 0);

  const totalEarnings = [
    payslip.basic_salary,
    payslip.hra,
    payslip.car_allowance,
    payslip.transport_allowance,
    payslip.others_addition,
  ].reduce((sum, value) => sum + parseFloat(value || 0), 0);

  const totalAdditions = [
    payslip.leave_encashment,
    payslip.overtime,
    payslip.compo_off,
    payslip.commission,
    payslip.other_allowance,
  ].reduce((sum, value) => sum + parseFloat(value || 0), 0);

  const netSalary = allowances - deductions;

  return {
    allowances: allowances.toFixed(2),
    deductions: deductions.toFixed(2),
    totalEarnings: totalEarnings.toFixed(2),
    totalAdditions: totalAdditions.toFixed(2),
    netSalary: netSalary.toFixed(2),
  };
};

export const getLastDayOfMonth = (month, year) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthIndex = monthNames.indexOf(month);

  const lastDay = new Date(year, monthIndex + 1, 0).getDate();

  return lastDay;
};
