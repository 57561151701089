import "../../styles/empManagement.css";
import employeeData from "../../assets/dummy-data/employeeData";
import eyeIcon from "../../assets/images/actionEye.png";
import { useState } from "react";
import { useEffect } from "react";
import SearchTable from "../../components/Reusable/SearchTable";
import { useNavigate } from "react-router-dom";

const PaymentHistoryTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCo, setSelectedCo] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [searchPressed, setSearchPressed] = useState(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    setSearchPressed(true);
  };

  const filteredItems = searchPressed
    ? employeeData.filter((employee) => {
        return (
          (selectedCo === "" || employee.empCompany === selectedCo) &&
          (selectedDepartment === "" ||
            employee.department === selectedDepartment) &&
          (selectedDate === "" || employee.createDate === selectedDate)
        );
      })
    : employeeData;

  // Pagination coding
  const itemsPerPage = 10;
  const totalPages = Math.ceil(employeeData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCo, selectedDepartment, selectedDate]);

  return (
    <div>
      {/* <SearchTable
        handleSearch={handleSearch}
        selectedCo={selectedCo}
        setSelectedCo={setSelectedCo}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      /> */}
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th colSpan={2}>Employee Name</th>
            <th>Joining Date</th>
            <th>WPS Salary</th>
            <th>WPS Status</th>
            <th>Commission Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((employee) => (
            <tr key={employee.id}>
              <td>{employee.empId}</td>
              <td>
                <img src={employee.empPicture} alt="Employee" />
              </td>
              <td>{employee.empName}</td>
              <td>{employee.joiningDate}</td>
              <td>{employee.wpsSalary}</td>
              <td>{employee.wpsStatus}</td>
              <td>{employee.commissionStatus}</td>
              <td className="table__data">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={eyeIcon}
                    alt="icon"
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    onClick={() => navigate("/employeedetails")}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (page) => (
            <button
              key={page}
              className={`pagination__button ${
                page === currentPage ? "active" : ""
              }`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default PaymentHistoryTable;
