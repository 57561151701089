import "./multiStep.css";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "./TextError";
import { useMemo, useRef, useEffect } from "react";
import countryList from "react-select-country-list";
import { BsArrowRight } from "react-icons/bs";
import { fieldsetStyles, legendStyles } from "../Reusable/styles";

const BasicInformation = (props) => {
  const countries = useMemo(() => countryList().getData(), []);
  const firstNameRef = useRef(null);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  const BasicInformationValidate = Yup.object({
    first_name: Yup.string()
      .min(3, "Must be 3 charecters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    last_name: Yup.string()
      .min(3, "Must be 3 charecters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    gender: Yup.string().required("A option is required"),
    marital_status: Yup.string().required("A option is required"),
    personal_mail_id: Yup.string()
      .email("Enter valid Email")
      .required("Email is required"),
    personal_phone_number: Yup.string()
      .min(10, "Number must be at least 10 charaters")
      .required("Number is required"),
    dob: Yup.date()
      .nullable()
      .required("Date of Birth is required")
      .max(new Date(), "Date of Birth cannot be in the future")
      .test("dob-age", "You must be at least 18 years old", (value) => {
        if (!value) return false;
        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
        return (
          age > 18 ||
          (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))
        );
      }),
    nationality: Yup.string().required("Required"),
    // emergency_contact_name: Yup.string()
    //   .min(3, "Must be 3 charecters or more")
    //   .max(20, "Must be 20 characters or less")
    //   .required("Required"),
    // emergency_contact_relation: Yup.string()
    //   .min(3, "Must be 3 charecters or more")
    //   .max(20, "Must be 20 characters or less")
    //   .required("Required"),
    // emergency_contact_number: Yup.string()
    //   .min(10, "Number must be at least 10 charaters")
    //   .required("Number is required"),
    // emergency_contact_email: Yup.string()
    //   .email("Enter valid Email")
    //   .required("Email is required"),
  });

  if (!props.empData.language) {
    props.empData.language = [
      { name: "", read: false, write: false, speak: false },
    ];
  }

  const handleAddField = (push) => {
    push({ name: "", read: false, write: false, speak: false });
  };

  const handleRemoveField = (remove, index) => {
    remove(index);
  };

  const handleSubmit = (values, ) => {
    props.next(values);
    console.log(values);
  };

  return (
    <Formik
      initialValues={props.empData}
      validationSchema={BasicInformationValidate}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <div className="form-rows">
            <div>
              <label htmlFor="first_name">First Name</label>
              <Field
                type="text"
                name="first_name"
                id="first_name"
                innerRef={firstNameRef}
                placeholder="Enter your Employee First Name"
              />
              <ErrorMessage name="first_name" component={TextError} />
            </div>
            <div>
              <label htmlFor="last_name">Last Name</label>
              <Field
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Enter your Employee Last Name"
              />
              <ErrorMessage name="last_name" component={TextError} />
            </div>
          </div>

          <div className="form-rows">
            <div>
              <label htmlFor="dob">Date of Birth</label>
              <Field
                type="date"
                name="dob"
                id="dob"
                placeholder="Enter your Employee Date of Birth"
              />
              <ErrorMessage name="dob" component={TextError} />
            </div>
            <div>
              <label htmlFor="personal_mail_id">Email</label>
              <Field
                type="email"
                name="personal_mail_id"
                id="personal_mail_id"
                placeholder="Enter Employee Personal Email Address"
              />
              <ErrorMessage name="personal_mail_id">
                {(errorMsg) => <div className="error">{errorMsg}</div>}
              </ErrorMessage>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              columnGap: "25rem",
              rowGap: "1rem",
              marginTop: "1rem",
            }}
          >
            <div>
              <label>Gender</label>
              <div>
                <div>
                  <label>
                    <Field type="radio" name="gender" value="Male" id="male" />
                    Male
                  </label>
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                  <label>
                    <Field
                      type="radio"
                      name="gender"
                      value="Female"
                      id="female"
                    />
                    Female
                  </label>
                </div>
              </div>
              <div style={{ color: "red", fontSize: "12px" }}>
                <ErrorMessage name="gender" />
              </div>
            </div>

            <div>
              <label style={{ whiteSpace: "nowrap" }}>Marital Status</label>
              <div>
                <div>
                  <label>
                    <Field
                      type="radio"
                      name="marital_status"
                      value="0"
                      id="single"
                    />
                    Single
                  </label>
                </div>
                <div>
                  <label>
                    <Field
                      type="radio"
                      name="marital_status"
                      value="1"
                      id="married"
                    />
                    Married
                  </label>
                </div>
              </div>
            </div>
            <div style={{ color: "red", fontSize: "12px", marginLeft: "0px" }}>
              <ErrorMessage name="marital_status" />
            </div>
          </div>

          <div className="form-rows">
            <div>
              <label htmlFor="work_phone_number">Office Mobile Number</label>
              <Field
                type="text"
                name="work_phone_number"
                id="work_phone_number"
                placeholder="Enter Employee Official Mobile Number"
              />
            </div>
            <div>
              <label htmlFor="personal_phone_number">
                Personal Mobile Number
              </label>
              <Field
                type="text"
                name="personal_phone_number"
                id="personal_phone_number"
                placeholder="Enter Employee Personal Mobile Number"
              />
              <ErrorMessage
                name="personal_phone_number"
                component={TextError}
              />
            </div>
          </div>
          <div className="form-rows">
            <div>
              <label htmlFor="emergency_contact_name">
                Emergency Contact Person
              </label>
              <Field
                type="text"
                name="emergency_contact_name"
                id="emergency_contact_name"
                placeholder="Enter Employee Emergency Contact Person Name"
              />
              <ErrorMessage
                name="emergency_contact_name"
                component={TextError}
              />
            </div>
            <div>
              <label htmlFor="emergency_contact_relation">
                Relation with Employee
              </label>
              <Field
                type="text"
                name="emergency_contact_relation"
                id="emergency_contact_relation"
                placeholder="Relation with Employee"
              />
              <ErrorMessage
                name="emergency_contact_relation"
                component={TextError}
              />
            </div>
          </div>
          <div className="form-rows">
            <div>
              <label htmlFor="emergency_contact_number">
                Emergency Contact Number
              </label>
              <Field
                type="text"
                name="emergency_contact_number"
                id="emergency_contact_number"
                placeholder="Enter Employee Emergency Contact Number"
              />
              <ErrorMessage
                name="emergency_contact_number"
                component={TextError}
              />
            </div>
            <div>
              <label htmlFor="emergency_contact_email">
                Emergency Contact Email
              </label>
              <Field
                type="email"
                name="emergency_contact_email"
                id="emergency_contact_email"
                placeholder="Enter Employee Emergency Contact Email"
              />
              <ErrorMessage
                name="emergency_contact_email"
                component={TextError}
              />
            </div>
          </div>
          <div className="form-rows">
            <div>
              <label htmlFor="nationality">Nationality</label>
              <Field
                name="nationality"
                as="select"
                placeholder="Choose your Nationality"
              >
                <option value="">Select Country</option>

                {countries.map((country, index) => (
                  <option value={country.id} key={index}>
                    {" "}
                    {country.label}{" "}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="nationality" component={TextError} />
            </div>
            <div></div>
          </div>
          <FieldArray name="language">
            {({ push, remove }) => (
              <div>
                {formik.values.language.map((field, index) => (
                  <fieldset style={fieldsetStyles}>
                    <legend style={legendStyles}>{`Languages Known ${
                      index + 1
                    }`}</legend>
                    <div key={index} className="dynamic-field-container">
                      <div className="form-rows">
                        <div>
                          <label>Language</label>
                          <Field
                            type="text"
                            name={`language.${index}.name`}
                            placeholder={`Languages ${index + 1}`}
                          />
                        </div>
                        <div className="language_skills">
                          <div className="ability">
                            <label>
                              <Field
                                type="checkbox"
                                name={`language.${index}.read`}
                              />
                              Read
                            </label>
                          </div>
                          <div className="ability">
                            <label>
                              <Field
                                type="checkbox"
                                name={`language.${index}.write`}
                              />
                              Write
                            </label>
                          </div>
                          <div className="ability">
                            <label>
                              <Field
                                type="checkbox"
                                name={`language.${index}.speak`}
                              />
                              Speak
                            </label>
                          </div>
                          {index > 0 && (
                            <div className="button_delete">
                              <button
                                type="button"
                                onClick={() => handleRemoveField(remove, index)}
                              >
                                X
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                ))}
                <div>
                  <button
                    type="button"
                    onClick={() => handleAddField(push)}
                    className="formik-addMore-btn"
                  >
                    + Add More Languages
                  </button>
                </div>
              </div>
            )}
          </FieldArray>

          <div className="form-btn-div">
            <button type="submit" className="form-btn-submit">
              Save & Continue <BsArrowRight />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BasicInformation;
