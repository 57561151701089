import { useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import TextError from "./TextError";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import * as Yup from "yup";
import { fieldsetStyles, legendStyles } from "../Reusable/styles";
import { useNavigate } from "react-router-dom";

const Documents = (props) => {
  console.log("documents page", props);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    documents: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Document Name is required"),
        document_number: Yup.string().when("name", {
          is: (name) =>
            name !== "Resume" &&
            name !== "profilePicture" &&
            selectedDocument !== "Resume" &&
            selectedDocument !== "profilePicture",
          then: Yup.string().required("Document Number is required"),
          otherwise: Yup.string(),
        }),
        expiry_date: Yup.date().when("name", {
          is: (name) =>
            name !== "Resume" &&
            name !== "profilePicture" &&
            selectedDocument !== "Resume" &&
            selectedDocument !== "profilePicture",
          then: Yup.date().required("Expiry Date is required"),
          otherwise: Yup.date(),
        }),
        file: Yup.mixed().when("name", {
          is: (name) => name !== "Resume" && name !== "profilePicture",
          then: Yup.mixed()
            .required("Document Image is required")
            .test(
              "fileFormat",
              "Unsupported file format",
              (value) =>
                value &&
                ["image/jpeg", "image/png", "application/pdf"].includes(
                  value.type
                )
            ),
          otherwise: Yup.mixed(),
        }),
      })
    ),
  });

  const handleSubmit = (values) => {
    const validDocuments = values.documents.filter(
      (doc) => doc.name && doc.file
    );

    if (validDocuments.length === 0) {
      setShowModal(true);
      return;
    }

    const documents = values.documents.map((doc) => {
      if (doc.name === "Resume" || doc.name === "profilePicture") {
        return {
          ...doc,
          document_number: "",
          expiry_date: "",
        };
      }
      return doc;
    });

    props.next({ ...values, documents });
  };

  const handlePrevious = (values) => {
    props.onBack(values);
  };

  return (
    <div>
      <Formik
        initialValues={props.empData}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="documents">
              {({ insert, remove, push }) => (
                <div>
                  {values.documents.length > 0 &&
                    values.documents.map((doc, index) => (
                      <fieldset style={fieldsetStyles}>
                        <legend style={legendStyles}>{`Document Details ${
                          index + 1
                        }`}</legend>
                        <div className="form-rows">
                          <div>
                            <label htmlFor={`documents.${index}.name`}>
                              Document Name
                            </label>
                            <Field
                              name={`documents.${index}.name`}
                              as="select"
                              onChange={(e) => {
                                setSelectedDocument(e.target.value);
                                setFieldValue(
                                  `documents.${index}.name`,
                                  e.target.value
                                );
                              }}
                            >
                              <option value="">Select the Document</option>
                              <option value="profilePicture">
                                Profile Picture
                              </option>
                              <option value="Resume">Resume</option>
                              <option value="ID Card">EID Card</option>
                              <option value="Passport">Passport</option>
                              <option value="Visa">Visa</option>
                              <option value="Insurance Card">
                                Insurance Card
                              </option>
                            </Field>
                            <ErrorMessage
                              component={TextError}
                              name={`documents.${index}.name`}
                            />
                          </div>

                          <div>
                            <label htmlFor={`documents.${index}.file`}>
                              {values.documents[index].name !== "profilePicture"
                                ? "Upload the Document"
                                : "Upload the Image"}
                            </label>
                            <input
                              name={`documents.${index}.file`}
                              className="custom-file-upload"
                              type="file"
                              id={`documents.${index}.file`}
                              onChange={(event) => {
                                setFieldValue(
                                  `documents.${index}.file`,
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            <ErrorMessage
                              component={TextError}
                              name={`documents.${index}.file`}
                            />
                          </div>
                        </div>
                        {values.documents[index].name !== "Resume" &&
                          values.documents[index].name !== "profilePicture" && (
                            <div className="form-rows">
                              <div>
                                <label
                                  htmlFor={`documents.${index}.document_number`}
                                >
                                  Document Number
                                </label>
                                <Field
                                  name={`documents.${index}.document_number`}
                                  placeholder={`${selectedDocument} Number`}
                                  type="text"
                                />
                                <ErrorMessage
                                  component={TextError}
                                  name={`documents.${index}.document_number`}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor={`documents.${index}.expiry_date`}
                                >
                                  Expiry Date
                                </label>
                                <Field
                                  name={`documents.${index}.expiry_date`}
                                  placeholder="Expiry Date"
                                  type="date"
                                />
                                <ErrorMessage
                                  component={TextError}
                                  name={`documents.${index}.expiry_date`}
                                />
                              </div>
                            </div>
                          )}
                        {values.documents[index].name === "Passport" && (
                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`documents.${index}.country_of_issue`}
                              >
                                Issuing Country
                              </label>
                              <Field
                                name={`documents.${index}.country_of_issue`}
                                placeholder={`${selectedDocument} Issuing Country`}
                                type="text"
                              />
                              <ErrorMessage
                                component={TextError}
                                name={`documents.${index}.country_of_issue`}
                              />
                            </div>
                            <div></div>
                          </div>
                        )}
                        <div className="col">
                          {index > 0 && (
                            <>
                              <button
                                type="button"
                                className="formik-delete-btn"
                                onClick={() => remove(index)}
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </div>
                      </fieldset>
                    ))}
                  <button
                    type="button"
                    className="formik-addMore-btn"
                    onClick={() =>
                      push({
                        name: "",
                        document_number: "",
                        expiry_date: "",
                        file: "",
                        issuing_country: "",
                        is_approved: 1,
                      })
                    }
                  >
                    Add More documents +
                  </button>
                </div>
              )}
            </FieldArray>
            <div className="form-btn-div">
              <button
                onClick={handlePrevious}
                type="button"
                className="form-btn-previous"
              >
                <BsArrowLeft /> Previous
              </button>
              <button
                type="button"
                className="btn-skip-final"
                onClick={() =>
                  navigate(`/employeedirectory/${props.empData.id}`)
                }
              >
                Skip & Submit
              </button>
              <button type="submit" className="form-btn-submit">
                Submit <BsArrowRight />
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
            ⚠️ No Document Found
            </h3>
            <p className="text-sm text-gray-600 mb-6">
              Please add at least one document or use the <br />
              <b className="text-green-600">Skip & Submit</b> button to exit.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition duration-300"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Documents;
