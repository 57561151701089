import "../../styles/leaveManagement.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import calendarIcon from "../../assets/images/calendar.svg";
import SingleCard from "../../components/Reusable/SingleCard";
import LeaveManagementTable from "./LeaveManagementTable";
import UtilityBarDates from "../../components/Reusable/UtilityBarDates";
import LeaveCalendar from "../../components/Reusable/LeaveCalendar";
import {
  totalLeaveRequest,
  totalLeaveApproved,
  totalLeaveRequestPending,
} from "../../components/Reusable/CardData";

const LeaveManagement = () => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem("selectedCompany") || ""
  );
  const [companyList, setCompanyList] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [minDate, setMinDate] = useState(null);
  const [view, setView] = useState(false);
  const [viewLeaveCalendar, setViewLeaveCalendar] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${month}/${day}/${year}`;
  };

  const startDateOnChange = (startDate) => {
    setStartDate(startDate);
    setSelectedStartDate(startDate);
    setMinDate(startDate);
  };

  const endDateOnChange = (endDate) => {
    setEndDate(endDate);
    setSelectedEndDate(endDate);
  };

  const onChange = (e) => {
    setSelectedCompany(e.target.value);
  };

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCompany();
  }, []);

  const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const monthEnd = currentDate.toISOString().substr(0, 10);

    const thirtyDaysAgo = new Date(
      currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
    );

    const monthStart = thirtyDaysAgo.toISOString().substr(0, 10);
    return {
      monthStart,
      monthEnd,
    };
  };

  const { monthStart, monthEnd } = getCurrentMonthDates();

  const getEmployeeLeaveData = async (Start, End) => {
    try {
      let apiUrl = "";
      const hasViewAllLeaveHistory = permissions.some(
        (permission) => permission.name === "view_all_leave_history"
      );
      const hasViewAllManagedLeaveHistory = permissions.some(
        (permission) => permission.name === "view_managed_leave_history"
      );
      

      if (hasViewAllManagedLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaveHistoryForManagers?from=${Start}&to=${End}`;
      } else if (hasViewAllLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/companyWiseLeaveHistory?from=${Start}&to=${End}&company=${selectedCompany}`;
      } else {
        alert("User does not have required permissions.");
        navigate("/dashboard");
        return;
      }
console.log("api url",apiUrl)
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setAttendanceData(dataArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeLeaveData(monthStart, monthEnd);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  }, [selectedCompany]);

  const formattedStartDate = formatDate(selectedStartDate);
  const formattedEndDate = formatDate(selectedEndDate);

  const clearAllDates = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    getEmployeeLeaveData(monthStart, monthEnd);
  };

  const searchButtonClick = () => {
    getEmployeeLeaveData(formattedStartDate, formattedEndDate);
    setView(false);
  };

  // const getStatusText = (employee) => {
  //   if (employee.is_hr_approved === 1 || employee.is_approved === 1) {
  //     return "Approved";
  //   } else if (employee.is_hr_approved === 0 || employee.is_approved === 0) {
  //     return "Declined";
  //   } else {
  //     return "Pending";
  //   }
  // };

  const getStatusText = (employee) => {
    if (employee.is_hr_approved === 1 || employee.is_approved === 1) {
      return "Approved";
    } else if (employee.is_hr_approved === 0 || employee.is_approved === 0) {
      return "Declined";
    } else if (employee.is_hr_approved === 3 || employee.is_approved === 3) {
      return "Forwarded";
    } else {
      return "Pending";
    }
  };

  const today = new Date();
  const currentdate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

  const calculateTotal = (attendanceData, conditionFn) =>
    attendanceData.reduce(
      (total, attendance) => (conditionFn(attendance) ? total + 1 : total),
      0
    );

  const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

  const TotalLeaveRequests = calculateTotal(
    attendanceData,
    (attendance) => attendance.apply_date === currentdate
  );

  const TotalLeavesApproved = calculateTotal(attendanceData, (attendance) => {
    const applyDate = new Date(attendance.apply_date);
    return (
      applyDate >= thirtyDaysAgo &&
      applyDate <= today &&
      (attendance.is_approved || attendance.is_hr_approved)
    );
  });

  const TotalLeavein30days = calculateTotal(attendanceData, (attendance) => {
    const applyDate = new Date(attendance.apply_date);
    return (
      applyDate >= thirtyDaysAgo &&
      applyDate <= today &&
      attendance.is_approved === null &&
      attendance.is_hr_approved === null
    );
  });

  const handleLeaves = (route, stateKey) => {
    navigate(route, {
      state: { [stateKey]: attendanceData },
    });
  };

  console.log("attendance data", attendanceData);

  return (
    <div className="board__layout">
      <div className="section-header">
        <div>
          <h1>Leave Management</h1>
        </div>

        <div className="section-header-end">
          <div className="filter__icon">
            <i className="ri-home-4-fill"></i>
            <select
              className="filter-select"
              onChange={onChange}
              value={selectedCompany}
            >
              <option>All Companies</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.company_name}>
                  {company.company_name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <img
              src={calendarIcon}
              alt="icon"
              onClick={() => setViewLeaveCalendar(true)}
            />
          </div>
        </div>
      </div>
      {viewLeaveCalendar && (
        <div className="leave_calendar-main">
          <LeaveCalendar
            selectedCompany={selectedCompany}
            setViewLeaveCalendar={setViewLeaveCalendar}
          />
        </div>
      )}
      <div className="leave__mgt-Card">
        <div
          style={{ width: "100vh" }}
          onClick={() =>
            handleLeaves("/totalleaverqttable", "requestedLeavesToday")
          }
        >
          <SingleCard
            item={{
              ...totalLeaveRequest,
              count: TotalLeaveRequests,
              linkTo: "/totalleaverqttable",
            }}
          />
        </div>
        <div
          style={{ width: "100vh" }}
          onClick={() => handleLeaves("/leaveapprovedtable", "approvedLeaves")}
        >
          <SingleCard
            item={{
              ...totalLeaveApproved,
              count: TotalLeavesApproved,
              linkTo: "/leaveapprovedtable",
            }}
          />
        </div>
        <div
          style={{ width: "100vh" }}
          onClick={() => handleLeaves("/pendingleaverqttable", "pendingLeaves")}
        >
          <SingleCard
            item={{
              ...totalLeaveRequestPending,
              count: TotalLeavein30days,
              linkTo: "/pendingleaverqttable",
            }}
          />
        </div>
      </div>
      <UtilityBarDates
        startDate={startDate}
        endDate={endDate}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        minDate={minDate}
        view={view}
        setView={setView}
        startDateOnChange={startDateOnChange}
        endDateOnChange={endDateOnChange}
        clearAllDates={clearAllDates}
        searchButtonClick={searchButtonClick}
        attendanceData={attendanceData}
        getStatusText={getStatusText}
      />
      <LeaveManagementTable
        attendanceData={attendanceData}
        setAttendanceData={setAttendanceData}
        selectedCompany={selectedCompany}
        getEmployeeMonthWiseLeave={() =>
          getEmployeeLeaveData(monthStart, monthEnd)
        }
      />
    </div>
  );
};

export default LeaveManagement;
