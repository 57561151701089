import "../../styles/payslip.css";

const PayslipTable = ({
  title,
  what,
  total,
  remark,
  sections,
  headerClass,
}) => (
  <div className="payslip-table">
    <table className="table-auto border-collapse border border-gray-300 w-full text-11px mb-6">
      <tbody>
        <tr className="mb-4">
          <th className={`${headerClass} text-white p-2 text-left`}>{title}</th>
          <th className={`${headerClass} text-white p-2 text-center`}>
            {what}
          </th>
          <th className={`${headerClass} text-white p-2 text-center`}>
            {total}
          </th>
          <th className={`${headerClass} text-white p-2 text-center`}>
            {remark}
          </th>
        </tr>
        {sections.map((section, index) => (
          <tr key={`${title}-${index}`}>
            <td className="px-2 text-11px text-title-black font-medium border border-gray-300 p-2 text-left ">
              {section.label}
            </td>
            <td className="px-2 text-11px text-desc-gray font-medium border border-gray-300 p-2 text-end ">
              {section.value}
            </td>
            <td className="px-2 text-11px text-desc-gray font-medium border border-gray-300 p-2 text-center ">
              {section.total}
            </td>
            <td className="px-2 text-11px text-desc-gray font-medium border border-gray-300 p-2 text-center ">
              {section.remarks}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default PayslipTable;
