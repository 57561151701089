import { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";

const DownloadLeaveDetails = ({ employeeData, leaveCount, empCountry }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadLeaveDetails = () => {
    setIsDownloading(true);

    try {
      // Prepare the data for CSV
      const leaveTypes = [
        ...new Set(leaveCount.map((leave) => leave.LeaveType)),
      ];

      // Calculate totals
      const totalLeaves = leaveCount
        .filter(
          (leave) =>
            leave.country === empCountry && leaveTypes.includes(leave.LeaveType)
        )
        .reduce((total, leave) => total + leave.count, 0);

      const totalLeavesTaken = leaveCount
        .filter(
          (leave) =>
            leave.country === empCountry && leaveTypes.includes(leave.LeaveType)
        )
        .reduce((total, leave) => total + leave.leave_days_taken, 0);

      const availableLeaves = totalLeaves - totalLeavesTaken;

      // Employee information
      const employeeName = employeeData?.user?.employee_full_name || "N/A";
      const employeeId = employeeData?.user?.employee_id || "N/A";
      const companyName =
        employeeData?.user?.roles[0]?.company[0]?.name || "N/A";
      const designation = employeeData?.user?.roles[0]?.role_name || "N/A";

      // Create CSV headers
      let csvContent = "Employee Leave Details\n\n";
      csvContent += "Employee Information\n";
      csvContent += `Name,${employeeName}\n`;
      csvContent += `ID,${employeeId}\n`;
      csvContent += `Company,${companyName}\n`;
      csvContent += `Designation,${designation}\n`;
      csvContent += `Country,${empCountry}\n\n`;

      // Summary of leave statistics
      csvContent += "Leave Summary\n";
      csvContent += `Total Leaves,${totalLeaves}\n`;
      csvContent += `Leaves Taken,${totalLeavesTaken}\n`;
      csvContent += `Available Leaves,${availableLeaves}\n\n`;

      // Detailed breakdown by leave type
      csvContent += "Leave Details by Type\n";
      csvContent += "Leave Type,Total,Taken,Available\n";

      const countryLeaves = leaveCount.filter(
        (leave) => leave.country === empCountry
      );
      leaveTypes.forEach((leaveType) => {
        const leaveData = countryLeaves.find(
          (leave) => leave.LeaveType === leaveType
        ) || { count: 0, leave_days_taken: 0 };
        const total = leaveData.count;
        const taken = leaveData.leave_days_taken;
        const available = total - taken;

        csvContent += `${leaveType},${total},${taken},${available}\n`;
      });

      // Create a downloadable link
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a temporary link to trigger download
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `${employeeName.replace(/\s+/g, "_")}_Leave_Details.csv`
      );
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading leave details:", error);
      // Could add error handling UI here
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <p
      className="leave_history"
      style={{ cursor: "pointer" }}
      onClick={downloadLeaveDetails}
      disabled={isDownloading}
    >
      {isDownloading ? (
        <>
          <svg
            className="animate-spin h-4 w-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Processing...
        </>
      ) : (
        <div className="flex items-center">
          <MdOutlineFileDownload className="text-3xl"/>
          Download Leave Details
        </div>
      )}
    </p>
  );
};

export default DownloadLeaveDetails;
