import * as Yup from "yup";

export const SalaryBulkUploadValidationSchema = Yup.object({
  companyName: Yup.string().required("Required"),
  department: Yup.string().required("Required!"),
  salary_month: Yup.string()
    .required("Select a month and year")
    .matches(/^\d{4}-(0[1-9]|1[0-2])$/, "Please select a valid month and year")
    .test("future-date", "Cannot select future months", function (value) {
      if (!value) return true;
      const [year, month] = value.split("-");
      const selectedDate = new Date(year, month - 1);
      const today = new Date();
      return selectedDate <= today;
    })
    .test("year-range", "Year must be between 2000 and 2100", function (value) {
      if (!value) return true;
      const year = parseInt(value.split("-")[0]);
      return year >= 2000 && year <= 2100;
    }),
    PayslipFile: Yup.mixed()
    .required("Excel file is required")
    .test("fileType", "Only Excel files are allowed", (value) => {
      if (!value) return true;
      return (
        value.type === "application/vnd.ms-excel" || // .xls
        value.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // .xlsx
      );
    }),
});
