import "./navbar.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoNotifications, IoChatboxEllipsesSharp } from "react-icons/io5";
import { ImForward } from "react-icons/im";

import { capitalizeWords } from "../Reusable/capitalizeWords";

const Navbar = ({ userData }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const navigate = useNavigate();
  const profileMenuRef = useRef(null); 
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };

    if (showProfileMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showProfileMenu]);

  if (!userData || !userData.user || !Array.isArray(userData.user)) {
    return null;
  }

  const userArray = userData.user;
  const userImage = userArray.map((user) => user?.profile_picture);

  const handleProfileMenuClick = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleProfile = () => {
    navigate(`/employeedirectory/${userArray[0]?.employee_information_id}`);
    setShowProfileMenu(false);
  };

  const handleViewProfile = () => {
    navigate(`/viewdirectory/${userArray[0]?.employee_information_id}`);
    setShowProfileMenu(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userermissions");
    localStorage.setItem("selectedCompany", " ");
    localStorage.clear();
    navigate("/");
  };

  const cacheBuster = Math.random();

  return (
    <div>
      <div>
        <nav>
          <div className="notification">
            <IoNotifications className="font-icon" />
          </div>
          <IoChatboxEllipsesSharp className="font-icon" />
          <img
            src={`${userImage}?cache=${cacheBuster}`}
            alt="user-pic"
            className="user-pic"
            onClick={handleProfileMenuClick}
          />
          {userArray.map((user, index) => (
            <div
              className="sub-menu-wrap"
              key={index}
              ref={profileMenuRef} // Attach ref here
            >
              {showProfileMenu && (
                <div className="sub-menu">
                  <div className="user-info">
                    <div>
                      <img
                        src={`${user?.profile_picture}?cache=${cacheBuster}`}
                        alt="user-pic"
                      />
                    </div>
                    <div
                      onClick={handleViewProfile}
                      style={{ cursor: "pointer" }}
                    >
                      <h4>{capitalizeWords(user?.employee_full_name)}</h4>
                      <p>{userData.role}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="sub-menu-link" onClick={handleProfile}>
                    <div className="sub-menu-icon">
                      <i className="fa-solid fa-user transition-all"></i>
                    </div>
                    <p>Edit Profile</p>
                    <span>
                      <ImForward />
                    </span>
                  </div>
                  <div className="sub-menu-link">
                    <div className="sub-menu-icon">
                      <i className="fa-solid fa-gear transition-all"></i>
                    </div>
                    <p>Settings & Privacy</p>
                    <span>
                      <ImForward />
                    </span>
                  </div>
                  <div className="sub-menu-link">
                    <div className="sub-menu-icon">
                      <i
                        className="fa-solid fa-circle-question transition-all"
                        fa-2xl
                      ></i>
                    </div>
                    <p>Help & Support</p>
                    <span>
                      <ImForward />
                    </span>
                  </div>
                  <div className="sub-menu-link" onClick={handleLogout}>
                    <div className="sub-menu-icon">
                      <i className="fa-solid fa-right-from-bracket transition-all"></i>
                    </div>
                    <p>Logout</p>
                    <span>
                      <ImForward />
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
