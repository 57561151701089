import React, { useState } from "react";
import "../../styles/leaveManagement.css";
import approveBtn from "../../assets/images/yes.png";
import declineBtn from "../../assets/images/No.png";
import noApproveBtn from "../../assets/images/YesBW.png";
import noDeclineBtn from "../../assets/images/NoBW.png";
import { BsThreeDots } from "react-icons/bs";
import { AiTwotoneEdit, AiOutlineEye } from "react-icons/ai";
import EmployeeLeaveMoreDetails from "./EmployeeLeaveMoreDetails";
import ApproveDeclineLeave from "./ApproveDeclineLeave";
import UpdateLeaveStatus from "./UpdateLeaveStatus";
import formatDate from "../../components/Reusable/dateUtils";
import { capitalizeWords } from "../../components/Reusable/capitalizeWords";

const LeaveManagementTable = ({
  attendanceData,
  setAttendanceData,
  selectedCompany,
  getEmployeeMonthWiseLeave,
}) => {
  const [activeMenuId, setActiveMenuId] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [showEmployeeLeaveDetails, setShowEmployeeLeaveDetails] =
    useState(false);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);
  // const [showEditLeaveDetails, setShowEditLeaveDetails] = useState(null);
  const [showUpdateLeaveDetails, setShowUpdateLeaveDetails] = useState(null);
  const [showApproveDeclinePage, setShowApproveDeclinePage] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleLeaveMoreDetails = (leaveId) => {
    const selectedEmployee = attendanceData.find(
      (employee) => employee.leave_id === leaveId
    );
    if (selectedEmployee) {
      setSelectedEmployeeData(selectedEmployee);
      setShowEmployeeLeaveDetails(true);
      setActiveMenuId(false);
    }
  };

  // const handleEditLeaveDetails = (leaveId) => {
  //   const selectedEmployee = attendanceData.find(
  //     (employee) => employee.leave_id === leaveId
  //   );
  //   if (selectedEmployee) {
  //     setSelectedEmployeeData(selectedEmployee);
  //     setShowEditLeaveDetails(true);
  //   }
  // };

  const handleUpdateLeaveDetails = (leaveId) => {
    const selectedEmployee = attendanceData.find(
      (employee) => employee.leave_id === leaveId
    );
    if (selectedEmployee) {
      setSelectedEmployeeData(selectedEmployee);
      setShowUpdateLeaveDetails(true);
      setActiveMenuId(false);
    }
  };

  const handleLeaveApprove = (leaveId) => {
    const selectedEmployee = attendanceData.find(
      (employee) => employee.leave_id === leaveId
    );
    if (selectedEmployee) {
      setSelectedEmployeeData(selectedEmployee);
      setShowApproveDeclinePage(true);
    }
  };

  const handleLeaveDecline = (leaveId) => {
    const selectedEmployee = attendanceData.find(
      (employee) => employee.leave_id === leaveId
    );
    if (selectedEmployee) {
      setSelectedEmployeeData(selectedEmployee);
      setShowApproveDeclinePage(true);
    }
  };

  const handleToggleMenu = (leaveId, e) => {
    if (activeMenuId === leaveId) {
      setActiveMenuId(null);
    } else {
      const rect = e.currentTarget.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      setActiveMenuId(leaveId);
      const menuWidth = 100;
      const menuLeft = rect.left + window.scrollX;
      const menuRight = menuLeft + menuWidth;

      if (menuRight > screenWidth) {
        setMenuPosition({
          top: rect.bottom + window.scrollY - 15,
          right: screenWidth - menuLeft - 20,
        });
      } else {
        setMenuPosition({
          top: rect.bottom + window.scrollY - 15,
          left: menuLeft - 20,
        });
      }
    }
  };

  const getStatusColor = (employee) => {
    if (employee.is_hr_approved === 1 || employee.is_approved === 1) {
      return "green";
    } else if (employee.is_hr_approved === 0 || employee.is_approved === 0) {
      return "red";
    } else if (employee.is_hr_approved === 3 || employee.is_approved === 3) {
      return "gold";
    } else {
      return "orange";
    }
  };

  const getStatusText = (employee) => {
    if (employee.is_hr_approved === 1 || employee.is_approved === 1) {
      return "Approved";
    } else if (employee.is_hr_approved === 0 || employee.is_approved === 0) {
      return "Declined";
    } else if (employee.is_hr_approved === 3 || employee.is_approved === 3) {
      return "Forwarded";
    } else {
      return "Pending";
    }
  };

  const totalLeave = attendanceData.length;

  const handleSort = (columnName) => {
    const currentOrder = sortOrder[columnName];

    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    setSortOrder({ [columnName]: newOrder });
    const sortedData = [...attendanceData].sort((a, b) => {
      if (columnName === "status") {
        const statusOrder = { Approved: 1, Declined: 2, Pending: 3 };
        const statusA = getStatusText(a);
        const statusB = getStatusText(b);

        if (newOrder === "asc") {
          return statusOrder[statusA] - statusOrder[statusB];
        } else {
          return statusOrder[statusB] - statusOrder[statusA];
        }
      } else if (columnName === "employee_full_name") {
        if (newOrder === "asc") {
          return a[columnName].localeCompare(b[columnName]);
        } else {
          return b[columnName].localeCompare(a[columnName]);
        }
      } else {
        if (newOrder === "asc") {
          return a[columnName].localeCompare(b[columnName]);
        } else {
          return b[columnName].localeCompare(a[columnName]);
        }
      }
    });
    setAttendanceData(sortedData);
  };

  console.log("Leave Data in LMT", attendanceData);

  return (
    <div>
      <table className="leave_table">
        <thead>
          <tr>
            <th colSpan={2} onClick={() => handleSort("employee_full_name")}>
              Employee Details
              {sortOrder["employee_full_name"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("apply_date")}>
              Applied Date
              {sortOrder["apply_date"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("leave_from")}>
              Start Date
              {sortOrder["leave_from"] === "asc" ? "↑" : "↓"}
            </th>
            <th>End Date</th>
            <th>Days</th>
            <th onClick={() => handleSort("leaveType")}>
              Type
              {sortOrder["leaveType"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("status")}>
              Status
              {sortOrder["status"] === "asc" ? "↑" : "↓"}
            </th>
            <th>Actions</th>
            <th>More</th>
          </tr>
        </thead>
        <tbody>
          {attendanceData.map((employee, index) => (
            <tr key={index} id={employee.leave_id}>
              <td className="leave_empImage">
                <img
                  src={`${
                    employee?.user?.profile_picture
                  }?timestamp=${Date.now()}`}
                  alt="Employee"
                />
              </td>
              <td className="leave_empDetails">
                <div className="leave_empName">
                  {capitalizeWords(employee?.user?.employee_full_name)}
                </div>
                <div className="leave_designation">
                  {employee?.user?.roles[0]?.role_name}
                </div>
                <div className="leave_id">ID:{employee?.user?.employee_id}</div>
              </td>
              <td>{formatDate(employee.apply_date)}</td>
              <td>{formatDate(employee.leave_from)}</td>
              <td>{formatDate(employee.leave_to)}</td>
              <td>
                {employee.days_count} {employee.days_count > 1 ? "Days" : "Day"}
              </td>
              <td>{employee.leaveType}</td>
              <td style={{ color: getStatusColor(employee) }}>
                {getStatusText(employee)}
              </td>
              <td>
                {employee.is_hr_approved === 1 ||
                employee.is_approved === 1 ||
                employee.is_hr_approved === 0 ||
                employee.is_approved === 0 ? (
                  <>
                    <button
                      className="btn btn-approve"
                      onClick={() => handleLeaveApprove(employee.leave_id)}
                    >
                      <img src={noApproveBtn} alt="approve" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-decline"
                      onClick={() => handleLeaveDecline(employee.leave_id)}
                    >
                      <img src={noDeclineBtn} alt="decline" />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-approve"
                      onClick={() => handleLeaveApprove(employee.leave_id)}
                    >
                      <img src={approveBtn} alt="approve" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-decline"
                      onClick={() => handleLeaveDecline(employee.leave_id)}
                    >
                      <img src={declineBtn} alt="decline" />
                    </button>
                  </>
                )}
              </td>
              <td>
                <span
                  className="leave_icon-dots"
                  onClick={(e) => handleToggleMenu(employee.leave_id, e)}
                >
                  <BsThreeDots />
                </span>
                {activeMenuId === employee.leave_id && (
                  <div
                    className={`absolute right-6 top-[calc(100%+12px)] w-fit bg-white rounded-xl shadow-lg p-3 border border-gray-200 transform transition-all duration-300 ease-in-out scale-100 opacity-100`}
                    style={menuPosition}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ul className="flex flex-col list-none p-0 m-0 space-y-1">
                      <li
                        className="flex items-center gap-3 text-gray-700 font-medium cursor-pointer px-4 py-2 rounded-lg hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 hover:text-white transition-all duration-300 ease-in-out"
                        onClick={() =>
                          handleLeaveMoreDetails(employee.leave_id)
                        }
                      >
                        <span className="flex items-center gap-2 text-sm">
                          <AiOutlineEye className="text-base" />
                          View Details
                        </span>
                      </li>
                      <li
                        className="flex items-center gap-3 text-gray-700 font-medium cursor-pointer px-4 py-2 rounded-lg hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 hover:text-white transition-all duration-300 ease-in-out"
                        onClick={() =>
                          handleUpdateLeaveDetails(employee.leave_id)
                        }
                      >
                        <span className="flex items-center gap-2 text-sm">
                          <AiTwotoneEdit className="text-base" />
                          Edit Leave
                        </span>
                      </li>
                      
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showEmployeeLeaveDetails && (
        <div className="popup-overlay">
          <div className="popup-content">
            <EmployeeLeaveMoreDetails
              employeeData={selectedEmployeeData}
              setShowEmployeeLeaveDetails={setShowEmployeeLeaveDetails}
              selectedCompany={selectedCompany}
            />
          </div>
        </div>
      )}
      {showUpdateLeaveDetails && (
        <div className="popup-overlay">
          <div className="popup-content">
            <UpdateLeaveStatus
              employeeData={selectedEmployeeData}
              setShowUpdateLeaveDetails={setShowUpdateLeaveDetails}
              setSelectedEmployeeData={setSelectedEmployeeData}
              getEmployeeMonthWiseLeave={getEmployeeMonthWiseLeave}
            />
          </div>
        </div>
      )}
      {showApproveDeclinePage && (
        <div className="popup-overlay">
          <div className="popup-content">
            <ApproveDeclineLeave
              employeeData={selectedEmployeeData}
              setShowApproveDeclinePage={setShowApproveDeclinePage}
              selectedCompany={selectedCompany}
              getEmployeeMonthWiseLeave={getEmployeeMonthWiseLeave}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaveManagementTable;
