import ttflogo from "../../assets/images/TTF_Logo.png";
import rmslogo from "../../assets/images/RMSLogo.svg";
import scalexlogo from "../../assets/images/scalex.png";
import saldoslogo from "../../assets/images/saldos.webp";
import greenapplelogo from "../../assets/images/greenapple.jpg";
import bluebirdlogo from "../../assets/images/BlueBird.png";


export const defaultCompanyData = [
  {
    companyId: "1",
    companyName: "THE THOUGHT FACTORY DMCC",
    prefix: "TTF DMCC",
    companyLogo: ttflogo,
    address: ["United Arab Emirates", "25C, Silver Tower, JLT"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "ttf",
  },
  {
    companyId: "2",
    companyName: "TTF GENERAL TRADING LLC",
    prefix: "TTF LLC",
    companyLogo: ttflogo,
    address: ["United Arab Emirates", "25C, Silver Tower, JLT"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "ttf",
  },
  {
    companyId: "3",
    companyName: "TTF SALDOS GENERAL TRADING LLC",
    prefix: "TTF SALDOS",
    companyLogo: saldoslogo,
    address: ["United Arab Emirates", "25C, Silver Tower, JLT"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "saldos",
  },
  {
    companyId: "4",
    companyName: "RHAPSODY MERCHANDISING SERVICES LLC",
    prefix: "RMS LLC",
    companyLogo: rmslogo,
    address: ["26C, Silver Tower"],
    address2: ["JLT Dubai, United Arab Emirates"],
    colorCode: "rms",
  },
  {
    companyId: "5",
    companyName: "RHAPSODY ADVERTISING SERVICES",
    prefix: "RMS AS",
    companyLogo: rmslogo,
    address: ["United Arab Emirates", "25C, Silver Tower, JLT"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "rms",
  },
  {
    companyId: "6",
    companyName:
      "RHAPSODY FINISHING COMMODITIES & PRODUCTS FOR PROMOTION PURPOSES LLC",
    prefix: "RMS FCPPP ",
    companyLogo: rmslogo,
    address: ["United Arab Emirates", "25C, Silver Tower, JLT"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "rms",
  },
  {
    companyId: "7",
    companyName: "WOODWORKS INTERIOR DECORATION LLC",
    prefix: "WOOD WORKS",
    companyLogo: ttflogo,
    address: ["United Arab Emirates", "25C, Silver Tower, JLT"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "woodworks",
  },
  {
    companyId: "8",
    companyName: "GREEN APPLE SOFTWARE TRADING LLC",
    prefix: "GREEN APPLE",
    companyLogo: greenapplelogo,
    address: ["RKM Building", "Al Qiyadah Metro Station Office 315"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "greenapple",
  },
  {
    companyId: "9",
    companyName: "BLUE BIRD SIM CARDS TRADING LLC",
    prefix: "BLUE BIRD",
    companyLogo: bluebirdlogo,
    address: ["United Arab Emirates", "25C, Silver Tower, JLT"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "bluebird",
  },
  {
    companyId: "10",
    companyName: "SCALEX IT SOLUTIONS",
    prefix: "SCALEX",
    companyLogo: scalexlogo,
    address: [
      "Chakolas Heights, Seaport - Airport Rd,",
      "Chittethukara, Kakkanad",
    ],
    address2: ["Kochi, Kerala, India"],
    colorCode: "scalex",
  },
  {
    companyId: "11",
    companyName: "RHAPSODY MERCHANDISING SERVICES LLC - FZ",
    prefix: "RMS",
    companyLogo: rmslogo,
    address: ["United Arab Emirates", "25C, Silver Tower, JLT"],
    address2: ["Dubai", "United Arab Emirates"],
    colorCode: "rms",
  },
];







