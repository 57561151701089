import "../styles/dashboard.css";
import "../styles/attendance.css";
import axios from "axios";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SingleCard from "../components/Reusable/SingleCard";
import LeaveDisplayCard from "../components/Reusable/LeaveDisplayCard";
import userPurple from "../assets/images/user-group-purple.png";
import DocumentsCard from "../components/Reusable/DocumentsCard";
import AbsenceWithoutLeave from "../components/Reusable/AbsenceWoutLeaveCard";
import {
  totalEmployee,
  absentWoutLeave,
  employementVisa,
  employeePassport,
  emiratesId,
  medicalInsurance,
  dmccAccessCard,
  onBoarding,
  lateLogging,
  attendance,
  noOfLeavesApproved,
  newEmployee,
  employeesLeft,
} from "../components/Reusable/CardData";

const Dashboard = () => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem("selectedCompany") || ""
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    localStorage.getItem("selectedCompanyId") || ""
  );
  const [companyList, setCompanyList] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [employeeExitData, setEmployeeExitData] = useState([]);
  const [currentMonthDataLength, setCurrentMonthDataLength] = useState(0);
  const [totalLeaveRequest, setTotalLeaveRequest] = useState(0);
  const [newEmployeeList, setNewEmployeeList] = useState([]);
  const [listOfEmployeeExit, setListOfEmployeeExit] = useState([]);
  const [totalJoineesOfTheMonth, setTotalJoineesOfTheMonth] = useState(0);
  const [totalExitOfTheMonth, setTotalExitOfTheMonth] = useState(0);
  const navigate = useNavigate();

  let punchingTime;

  if (selectedCompany === "SCALEX IT SOLUTIONS") {
    punchingTime = "10:15:00";
  } else if (selectedCompany === "GREEN APPLE SOFTWARE TRADING LLC") {
    punchingTime = "09:45:00";
  } else if (selectedCompany === "BLUE BIRD SIM CARDS TRADING LLC") {
    punchingTime = "09:45:00";
  }

  const getEmployeeData = useCallback(async () => {
    try {
      let apiUrl = "";
      const timestamp = new Date().getTime();
      const hasViewAllManagedPermission = permissions.some(
        (permission) => permission.name === "view_all_managed_employees_info"
      );
      const hasViewPerGroupPermission = permissions.some(
        (permission) => permission.name === "view_per_group_all_employees_info"
      );

      if (hasViewAllManagedPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/employeesDataForManagers?timestamp=${timestamp}`;
      } else if (hasViewPerGroupPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation?company=${selectedCompany}&timestamp=${timestamp}`;
      } else {
        console.log("User does not have required permissions.");
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data?.data;
      const employees = data ? data.flatMap((item) => item.users) : [];

      setEmployeeData(employees);
    } catch (error) {
      console.log(error);
    }
  }, [selectedCompany, accessToken, permissions]);

  const getCompanyList = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => setCompanyList(response.data?.data))
      .catch((err) => console.log(err));
  });

  useEffect(() => {
    getEmployeeData();
  }, [selectedCompany]);

  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;

    const employeesJoinedThisMonth = employeeData.filter((employee) => {
      const joiningDate = new Date(employee.joining_date);
      return joiningDate.getMonth() + 1 === currentMonth;
    });

    setCurrentMonthDataLength(employeesJoinedThisMonth.length);
  }, [employeeData]);

  const updateTotalLeaveRequest = (totalLeave) => {
    setTotalLeaveRequest(totalLeave);
  };

  const onChange = (e, companyId) => {
    setSelectedCompanyId(companyId);
    setSelectedCompany(e.target.value);
    localStorage.setItem("selectedCompany", e.target.value);
    // localStorage.setItem("selectedCompanyId", companyId);
  };

  const totalEmployees = useMemo(() => {
    return employeeData.filter((employee) => employee.is_active).length;
  }, [employeeData]);

  //Visa Expiry Calculations

  const today = new Date();
  const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
  const oneTwentyDaysInMilliseconds = 120 * 24 * 60 * 60 * 1000;
  const OneEightyDaysInMilliseconds = 180 * 24 * 60 * 60 * 1000;

  const visaExpiryWithin30Days = [];
  const visaExpiryWithin120Days = [];

  const visaEmployees = employeeData.filter((employee) =>
    employee.documents.some((doc) => doc.name === "Visa")
  );

  visaEmployees.forEach((employee) => {
    const visaDocument = employee.documents.find((doc) => doc.name === "Visa");
    let expiryDate;
    if (
      visaDocument &&
      visaDocument.expiryDate &&
      visaDocument.expiryDate.trim() !== ""
    ) {
      expiryDate = new Date(visaDocument.expiryDate);
    } else {
      expiryDate = new Date("2023-01-01");
    }

    const timeDifference = expiryDate - today;

    if (timeDifference <= thirtyDaysInMilliseconds) {
      visaExpiryWithin30Days.push(employee);
    } else if (timeDifference <= oneTwentyDaysInMilliseconds) {
      visaExpiryWithin120Days.push(employee);
    }
  });

  const totalVisaExpiryWithin30Days = visaExpiryWithin30Days.length;
  const totalVisaExpiryWithin120Days = visaExpiryWithin120Days.length;

  const handleViewMoreVisa = (days) => {
    let dataToPass;
    if (days === 30) {
      dataToPass = visaExpiryWithin30Days;
      navigate("/visadetails", { state: { visaData: dataToPass } });
    } else if (days === 120) {
      dataToPass = visaExpiryWithin120Days;
      navigate("/visadetails", { state: { visaData: dataToPass } });
    }
  };

  //Passport Expiry Calculations
  const passportExpiryWithin30Days = [];
  const passportExpiryWithin180Days = [];

  const passportEmployees = employeeData.filter((employee) =>
    employee.documents.some((doc) => doc.name === "Passport")
  );

  passportEmployees.forEach((employee) => {
    const passportDocument = employee.documents.find(
      (doc) => doc.name === "Passport"
    );
    if (passportDocument && passportDocument.expiryDate) {
      const expiryDate = new Date(passportDocument.expiryDate);

      const timeDifference = expiryDate - today;

      if (timeDifference <= thirtyDaysInMilliseconds) {
        passportExpiryWithin30Days.push(employee);
      } else if (timeDifference <= OneEightyDaysInMilliseconds) {
        passportExpiryWithin180Days.push(employee);
      }
    }
  });

  const totalPassportExpiryWithin30Days = passportExpiryWithin30Days.length;
  const totalPassportExpiryWithin180Days = passportExpiryWithin180Days.length;

  const handleViewMorePassport = (days) => {
    let dataToPass;
    if (days === 30) {
      dataToPass = passportExpiryWithin30Days;
      navigate("/passportdetails", { state: { passportData: dataToPass } });
    } else if (days === 180) {
      dataToPass = passportExpiryWithin180Days;
      navigate("/passportdetails", { state: { passportData: dataToPass } });
    }
  };

  //Emirates ID Expiry Calculations
  const eidExpiryWithin30Days = [];

  const eidEmployees = employeeData.filter((employee) =>
    employee.documents.some((doc) => doc.name === "Eid")
  );

  eidEmployees.forEach((employee) => {
    const eidDocument = employee.documents.find((doc) => doc.name === "Eid");
    if (eidDocument && eidDocument.expiryDate) {
      const expiryDate = new Date(eidDocument.expiryDate);

      const timeDifference = expiryDate - today;

      if (timeDifference <= thirtyDaysInMilliseconds) {
        eidExpiryWithin30Days.push(employee);
      }
    }
  });

  const totalEidExpiryWithin30Days = eidExpiryWithin30Days.length;

  const handleViewMoreEid = () => {
    navigate("/emiratesiddetails", {
      state: { eidData: eidExpiryWithin30Days },
    });
  };

  //Medical Insurance Expiry Calculations
  const insuranceExpiryWithin30Days = [];

  const insuranceEmployees = employeeData.filter((employee) =>
    employee.documents.some((doc) => doc.name === "Insurance Card")
  );

  insuranceEmployees.forEach((employee) => {
    const insuranceDocument = employee.documents.find(
      (doc) => doc.name === "Insurance Card"
    );
    if (insuranceDocument && insuranceDocument.expiryDate) {
      const expiryDate = new Date(insuranceDocument.expiryDate);

      const timeDifference = expiryDate - today;

      if (timeDifference <= thirtyDaysInMilliseconds) {
        insuranceExpiryWithin30Days.push(employee);
      }
    }
  });

  const totalInsuranceExpiryWithin30Days = insuranceExpiryWithin30Days.length;

  const handleViewMoreInsurance = () => {
    navigate("/insurancecarddetails", {
      state: { insuranceData: insuranceExpiryWithin30Days },
    });
  };

  //Access Card Expiry Calculations
  const getEmployeesWithExpiringAccessCard = useCallback(() => {
    const currentDate = new Date();
    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;

    const employeesWithExpiringAccessCard = employeeData.filter((employee) => {
      if (employee.access_card_expiry_date) {
        const expiryDate = new Date(employee.access_card_expiry_date);
        const timeDifference = expiryDate - currentDate;

        return timeDifference <= sevenDaysInMilliseconds && timeDifference > 0;
      }
      return false;
    });

    return employeesWithExpiringAccessCard;
  });

  const accessCardExpiryWithin7Days = getEmployeesWithExpiringAccessCard();

  const totalAccessCardExpiryWithin7Days = accessCardExpiryWithin7Days.length;

  const handleViewMoreAccessCard = () => {
    navigate("/accesscarddetails", {
      state: { accessCardData: accessCardExpiryWithin7Days },
    });
  };

  const getEmployeeAttendance = useCallback(async () => {
    try {
      let apiUrl = "";
      const hasViewAllManagedPermission = permissions.some(
        (permission) => permission.name === "view_attendances_managed_employee"
      );
      const hasViewPerGroupPermission = permissions.some(
        (permission) => permission.name === "view_attendances_per_group"
      );

      if (hasViewAllManagedPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/attendances/managed`;
      } else if (hasViewPerGroupPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/attendances?company=${selectedCompany}`;
      } else {
        console.log("User does not have required permissions.");
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setAttendanceData(dataArray);
    } catch (error) {
      console.log(error);
    }
  });

  const getEmployeeLeave = useCallback(async () => {
    try {
      const currentDate = new Date();
      const toDate = currentDate.toISOString().substr(0, 10);
      currentDate.setDate(currentDate.getDate() - 30);
      const fromDate = currentDate.toISOString().substr(0, 10);

      let apiUrl = "";
      const hasViewAllManagedLeaveHistory = permissions.some(
        (permission) => permission.name === "view_managed_leave_history"
      );
      const hasViewAllLeaveHistory = permissions.some(
        (permission) => permission.name === "view_all_leave_history"
      );

      if (hasViewAllManagedLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaveHistoryForManagers?from=${fromDate}&to=${toDate}`;
      } else if (hasViewAllLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/companyWiseLeaveHistory?from=${fromDate}&to=${toDate}&company=${selectedCompany}`;
      } else {
        console.log("User does not have required permissions.");
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setLeaveData(dataArray);
    } catch (error) {
      console.log(error);
    }
  });

  const getEmployeeExitData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/ExitEmployeeData?company=${selectedCompany}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data?.data || [];
      setEmployeeExitData(data);
    } catch (error) {
      console.error(error);
    }
  }, [selectedCompany, accessToken]);

  useEffect(() => {
    getEmployeeAttendance();
    getEmployeeLeave();
    getEmployeeExitData();
  }, [selectedCompany]);

  const totalEmpEntries = attendanceData.length;
  let totalEmpLeave = attendanceData.filter(
    (item) => item.status !== "Present"
  ).length;
  let totalEmpPresent = totalEmpEntries - totalEmpLeave;

  const lateEmpLogins = attendanceData.map((employee, index) => {
    const startTime = employee.login_time;
    const isLate = startTime > punchingTime;
    return isLate;
  });

  const dataOfLateLogins = attendanceData.filter((employee) => {
    const startTime = employee.login_time;
    const isLate = startTime > punchingTime;
    return isLate;
  });

  const moveToLateLogins = () => {
    const stateData = {
      lateLoginData: dataOfLateLogins,
      selectedCompany: selectedCompany,
    };
    navigate("/latelogins", { state: stateData });
  };
  const moveToNewEmployeeList = () => {
    const stateData = {
      employeeList: newEmployeeList,
      selectedCompany: selectedCompany,
    };
    navigate("/newemployee", { state: stateData });
  };
  const moveToEmployeeLeft = () => {
    const stateData = {
      employeeExitList: listOfEmployeeExit,
      selectedCompany: selectedCompany,
    };
    navigate("/employeeleft", { state: stateData });
  };

  const numberOfLateLogins = lateEmpLogins.filter((isLate) => isLate).length;

  const TotalLeavesApproved = leaveData.reduce((totalapproved, leave) => {
    if (leave.is_approved && leave.is_approved) {
      totalapproved++;
    }
    return totalapproved;
  }, 0);

  useEffect(() => {
    const currentDate = new Date();
    const last30DaysDate = new Date();
    last30DaysDate.setDate(currentDate.getDate() - 30);

    const filteredData = employeeData.filter((record) => {
      const joiningDate = new Date(record.joining_date);
      return joiningDate >= last30DaysDate && joiningDate <= currentDate;
    });
    setNewEmployeeList(filteredData);
    const totalJoinees = filteredData.length;
    setTotalJoineesOfTheMonth(totalJoinees);
  }, [employeeData]);

  useEffect(() => {
    const currentDate = new Date();
    const last30DaysDate = new Date();
    last30DaysDate.setDate(currentDate.getDate() - 30);

    const filteredData = employeeExitData.filter((record) => {
      const lastWorkingDate = new Date(record.last_working_date);
      return (
        lastWorkingDate >= last30DaysDate && lastWorkingDate <= currentDate
      );
    });
    setListOfEmployeeExit(filteredData);
    const totalExit = filteredData.length;
    setTotalExitOfTheMonth(totalExit);
  }, [employeeExitData]);

  console.log("Dashboard selectedCompanyId", selectedCompanyId);

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Dashboard</h1>
        <div className="filter__icon">
          <i className="ri-home-4-fill"></i>
          <select
            className="filter-select"
            onChange={(e) => {
              const selectedCompanyName = e.target.value;
              const selectedCompany = companyList.find(
                (company) => company.company_name === selectedCompanyName
              );
              const companyId = selectedCompany ? selectedCompany.id : null;
              onChange(e, companyId);
            }}
            value={selectedCompany}
          >
            <option value="">All Companies</option>
            {companyList.map((company, index) => (
              <option value={company.company_name} key={index}>
                {company.company_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="section-wrapper">
        {/* Top Section  */}
        <section className="first-section">
          {/* Part 1 Top Left */}
          <div className="dashboard-cards-section-topleft">
            <div className="grid grid-cols-2 mb-2 gap-2">
              <div
                className="dashboard-single-card"
                onClick={() => navigate("/empmanagement")}
              >
                <SingleCard
                  selectedCompany={selectedCompany}
                  item={{
                    ...totalEmployee,
                    count: totalEmployees,
                    month: `+ ${currentMonthDataLength} this month`,
                    linkTo: "/empmanagement",
                  }}
                />
              </div>
              <div onClick={moveToNewEmployeeList}>
                <SingleCard
                  item={{
                    ...newEmployee,
                    count: currentMonthDataLength,
                    month: `+ ${currentMonthDataLength} this month`,
                  }}
                />
              </div>
            </div>

            <div className="visa_card-section">
              <div className="employementVisa">
                <DocumentsCard
                  item={{
                    ...employementVisa,
                    countMin: totalVisaExpiryWithin30Days,
                    countMax: totalVisaExpiryWithin120Days,
                    handleFirst: () => handleViewMoreVisa(30),
                    handleSecond: () => handleViewMoreVisa(120),
                  }}
                />
              </div>
              <div className="employeePassport">
                <DocumentsCard
                  item={{
                    ...employeePassport,
                    countMin: totalPassportExpiryWithin30Days,
                    countMax: totalPassportExpiryWithin180Days,
                    handleFirst: () => handleViewMorePassport(30),
                    handleSecond: () => handleViewMorePassport(180),
                  }}
                />
              </div>
              <div className="emiratesId">
                <DocumentsCard
                  item={{
                    ...emiratesId,
                    countMin: totalEidExpiryWithin30Days,
                    handleFirst: handleViewMoreEid,
                  }}
                />
              </div>
              <div className="medicalInsurance">
                <DocumentsCard
                  item={{
                    ...medicalInsurance,
                    countMin: totalInsuranceExpiryWithin30Days,
                    handleFirst: handleViewMoreInsurance,
                  }}
                />
              </div>
              <div className="dmccAccess">
                <DocumentsCard
                  item={{
                    ...dmccAccessCard,
                    countMin: totalAccessCardExpiryWithin7Days,
                    handleFirst: handleViewMoreAccessCard,
                  }}
                />
              </div>
            </div>
          </div>

          {/* Part 2 Top Right */}
          <div className="dashboard-cards-section-bottomright">
            <div className="leave__request-list">
              <div className="leave__main-section">
                <div className="leave__section-one ">
                  <div className="color__purple-light card__icon-img">
                    <img src={userPurple} alt="icon" />
                  </div>
                  <h4>{totalLeaveRequest}</h4>
                </div>
                <div
                  className="leave__section-two"
                  onClick={() => navigate("/leavemanagement")}
                >
                  <p>Leave Request</p>
                  <p>Last 30 Days</p>
                </div>
              </div>
              <div className="leave__display-card">
                <LeaveDisplayCard
                  selectedCompany={selectedCompany}
                  updateTotalLeaveRequest={updateTotalLeaveRequest}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Bottom Section */}
        <section className="second-section">
          {/* Part 3 Bottom Left */}
          <div className="dashboard-cards-section-bottomleft">
            <div className="single-cards-bottom-section">
              <div>
                <SingleCard item={{ ...attendance, count: totalEmpPresent }} />
              </div>
              <div>
                <SingleCard
                  item={{ ...noOfLeavesApproved, count: TotalLeavesApproved }}
                />
              </div>
              <div onClick={moveToLateLogins}>
                <SingleCard
                  item={{ ...lateLogging, count: numberOfLateLogins }}
                />
              </div>
              <div>
                <SingleCard item={absentWoutLeave} />
              </div>

              <div>
                <SingleCard item={onBoarding} />
              </div>
              <div onClick={moveToEmployeeLeft}>
                <SingleCard
                  item={{ ...employeesLeft, count: totalExitOfTheMonth }}
                />
              </div>
            </div>
          </div>

          {/* Part 4 Bottom Right */}
          <div className="dashboard-cards-section-topright">
            <div className="leave__request-absent">
              <div className="leave__main-section">
                <div className="leave__section-two">
                  <p>Absent without Leave</p>
                  <p>Last 30 Days</p>
                </div>
              </div>
              <div className="leave__display-card">
                <AbsenceWithoutLeave
                  selectedCompany={selectedCompany}
                  attendanceData={attendanceData}
                  leaveData={leaveData}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
