import "../../styles/empManagement.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../../assets/images/actionEye.png";
import dollarIcon from "../../assets/images/dollarIcon.png";
import SalarySearchTable from "./SalarySearchTable";
import {
  getCompany,
  getDepartments,
  getSalaryDataOnPageLoad,
  getSalaryDataOnSearch,
} from "../../utils/CallAPIs/apiStore";

const EmployeeSalaryTable = () => {
  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem("selectedCompany") || ""
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    localStorage.getItem("selectedCompanyId") || ""
  );
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [employeeSalaryData, setEmployeeSalaryData] = useState([]);
  const [salaryMonth, setSalaryMonth] = useState("");
  const [salaryYear, setSalaryYear] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCompany) {
      getDepartments(selectedCompany, setDepartmentList);
    }
  }, [selectedCompany, getDepartments]);

  useEffect(() => {
    const now = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    setCurrentMonth(monthNames[now.getMonth()]);
    setCurrentYear(now.getFullYear().toString());
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        if (selectedCompanyId) {
          await getCompany(setCompanyList);
          await getSalaryDataOnPageLoad(
            selectedCompanyId,
            currentMonth,
            currentYear,
            setEmployeeSalaryData
          );
        }
      } catch (error) {
        console.error("Error loading initial data:", error);
      }
    };

    loadInitialData();
  }, [selectedCompanyId, currentMonth, currentYear]);

  useEffect(() => {
    getCompany(setCompanyList);
    getSalaryDataOnPageLoad(
      selectedCompanyId,
      currentMonth,
      currentYear,
      setEmployeeSalaryData
    );
  }, [getSalaryDataOnPageLoad]);

  const handleSearch = () => {
    if (!companyId || !salaryMonth || !salaryYear) {
      console.error("Required parameters are missing!");
      return;
    }
    getSalaryDataOnSearch(
      companyId,
      departmentId,
      salaryMonth,
      salaryYear,
      setEmployeeSalaryData
    );
  };

  let handleShowPayslip = (id) => {
    console.log("when click on view", id);
    let employeeSalaryDetails = employeeSalaryData.filter(
      (data) => data.employee_id === id
    );
    console.log("employeeSalaryDetails", employeeSalaryDetails);
    navigate(`/payslip/${id}`, {
      state: { salaryDetails: employeeSalaryDetails },
    });
  };

  // Pagination coding
  const itemsPerPage = 10;
  const totalPages = Math.ceil(employeeSalaryData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employeeSalaryData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div>
        <SalarySearchTable
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          setCompanyId={setCompanyId}
          setDepartmentId={setDepartmentId}
          companyList={companyList}
          departmentList={departmentList}
          setSalaryMonth={setSalaryMonth}
          setSalaryYear={setSalaryYear}
          handleSearch={handleSearch}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Employee Name</th>
            <th>Month & Year</th>
            <th>Basic Salary</th>
            <th>Create Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((employee) => (
              <tr key={employee?.employee_id}>
                <td>{employee?.employee_id}</td>
                <td>{employee?.employee_name}</td>
                <td>
                  {employee?.payslip?.month} {employee?.payslip?.year}
                </td>
                <td>{employee?.payslip?.basic_salary}</td>
                <td>
                  {employee?.payslip?.updated_at
                    ? new Date(employee.payslip.updated_at)
                        .toISOString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("-")
                    : ""}
                </td>
                <td>{employee?.payslip?.basic_salary ? "Paid" : "Unpaid"}</td>
                <td>
                  <div className="flex justify-center">
                    <img
                      src={eyeIcon}
                      alt="icon"
                      className="mr-1 cursor-pointer w-9 h-9"
                      onClick={() => handleShowPayslip(employee?.employee_id)}
                    />
                    {/* <img
                      src={dollarIcon}
                      alt="icon"
                      style={{ cursor: "pointer" }}
                    /> */}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={7}>No Salary Data</td>
          )}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (page) => (
            <button
              key={page}
              className={`pagination__button ${
                page === currentPage ? "active" : ""
              }`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default EmployeeSalaryTable;
