import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import AddEmployeeForm from "../pages/AddEmployeeForm";
import AddEmployeeSalary from "../pages/PayrollManagement/AddEmployeeSalary";
import EmployeeDetails from "../pages/EmployeeDetails";
import EmployeeAttendanceDetails from "../pages/EmployeeAttendanceDetails";
import EmployeeManagement from "../pages/EmployeeManagement";
import EmployeeSalary from "../pages/PayrollManagement/EmployeeSalary";
import ExitManagement from "../pages/ExitManagement";
import ExitManagementForm from "../pages/ExitManagementForm";
import LearnDevelop from "../pages/LearnDevelop";
import LeaveManagement from "../pages/LeaveManagement/LeaveManagement";
import OnBoard from "../pages/OnBoard";
import OnBoardAddEmployee from "../pages/OnBoardingAddEmpForm";
import PaymentHistory from "../pages/PayrollManagement/PaymentHistory";
import Recruitment from "../pages/Recruitment";
import Support from "../pages/Support";
import TimeAttendance from "../pages/TimeAttendance";
import LoginPage from "../pages/LoginPage";
import TimeSettings from "../pages/TimeSettings";
import EmployeeLeaveMoreDetails from "../pages/LeaveManagement/EmployeeLeaveMoreDetails";
import EditLeaveDetails from "../pages/LeaveManagement/EditLeaveDetails";
import EmployeeLeaveHistoryTable from "../components/Reusable/EmployeeLeaveHistoryTable";
import EmployeeDirectory from "../components/MultiStep/EmployeeDirectory";
import Layout from "../components/Layout/Layout";
import ExitManagementView from "../components/Reusable/ExitManagementView";
import BasicInformationUpdate from "../components/EditMultiStepForm/BasicInformationUpdate";
import AddressUpdate from "../components/EditMultiStepForm/AddressUpdate";
import JoiningDetailsUpdate from "../components/EditMultiStepForm/JoiningDetailsUpdate";
import QualificationUpdate from "../components/EditMultiStepForm/QualificationUpdate";
import ExperienceUpdate from "../components/EditMultiStepForm/ExperienceUpdate";
import DocumentsUpdate from "../components/EditMultiStepForm/DocumentsUpdate";
import VisaDetails from "../components/Reusable/VisaDetails";
import VisaDocumentsUpdate from "../components/EditMultiStepForm/VisaDocumentUpdate";
import PassportDetails from "../components/Reusable/PassportDetails";
import PassportDocumentsUpdate from "../components/EditMultiStepForm/PassportDocumentUpdate";
import AddEmployeeSalaryForm from "../pages/PayrollManagement/AddEmployeeSalaryForm";
import LateLoginsDetails from "../components/Reusable/LateLoginsDetails";
import NewEmployeeDetails from "../components/Reusable/NewEmployeeDetails";
import EmployeeLeftDetails from "../components/Reusable/EmployeeLeftDetails";
import Page403 from "../pages/Page403";
import RoleManagement from "../pages/RoleManagement";
import RoleManagementForm from "../pages/RoleManagementForm";
import RoleManagementEdit from "../pages/RoleManagementEdit";
import EmiratesIdUpdate from "../components/EditMultiStepForm/EmiratesIdUpdate";
import EmiratesIdDetails from "../components/Reusable/EmiratesIdDetails";
import InsuranceCardUpdate from "../components/EditMultiStepForm/InsuranceCardUpdate";
import InsuranceCardDetails from "../components/Reusable/InsuranceCardDetails";
import AccessCardUpdate from "../components/EditMultiStepForm/AccessCardUpdate";
import AccessCardDetails from "../components/Reusable/AccessCardDetails";
import ViewEmployeeInfo from "../components/Reusable/ViewEmployeeInfo";
import TotalLeaveRequestTable from "../components/Reusable/TotalLeaveRequestTable";
import TotalLeaveApprovedTable from "../components/Reusable/TotalLeaveApprovedTable";
import PendingLeaveRequestsTable from "../components/Reusable/PendingLeaveRequestsTable";
import EmployeeDocumentsUpdate from "../components/EditMultiStepForm/EmployeeDocumentsUpdate";
import SalaryBulkUpload from "../pages/PayrollManagement/SalaryBulkUpload";
import Payslip from "../pages/PayrollManagement/PaySlip";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/restricted" element={<Page403 />} />
      <Route element={<Layout />}>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/addemployee" element={<AddEmployeeForm />} />
        <Route path="/addemployeesalary" element={<AddEmployeeSalary />} />
        <Route
          path="/addemployeesalaryform"
          element={<AddEmployeeSalaryForm />}
        />
        <Route path="/empmanagement" element={<EmployeeManagement />} />
        <Route path="/employeesalary" element={<EmployeeSalary />} />
        <Route path="/payslip/:id" element={<Payslip />} />
        <Route path="/bulkuploadsalarylist" element={<SalaryBulkUpload />} />
        <Route path="/employeedetails" element={<EmployeeDetails />} />
        <Route path="/visadetails" element={<VisaDetails />} />
        <Route path="/passportdetails" element={<PassportDetails />} />
        <Route path="/emiratesiddetails" element={<EmiratesIdDetails />} />
        <Route
          path="/insurancecarddetails"
          element={<InsuranceCardDetails />}
        />
        <Route path="/accesscarddetails" element={<AccessCardDetails />} />
        <Route path="/latelogins" element={<LateLoginsDetails />} />
        <Route path="/newemployee" element={<NewEmployeeDetails />} />
        <Route path="/employeeleft" element={<EmployeeLeftDetails />} />
        <Route
          path="/employeeattendancedetails/:id"
          element={<EmployeeAttendanceDetails />}
        />
        <Route
          path="/basicinformationupdate/:id"
          element={<BasicInformationUpdate />}
        />
        <Route path="/addressupdate/:id" element={<AddressUpdate />} />
        <Route path="/joiningupdate/:id" element={<JoiningDetailsUpdate />} />
        <Route
          path="/qualificationupdate/:id"
          element={<QualificationUpdate />}
        />
        <Route path="/experienceupdate/:id" element={<ExperienceUpdate />} />
        <Route path="/documentsupdate/:id" element={<DocumentsUpdate />} />
        <Route path="/visaupdate/:id" element={<VisaDocumentsUpdate />} />
        <Route
          path="/passportupdate/:id"
          element={<PassportDocumentsUpdate />}
        />
        <Route
          path="/employeedocumentsupdate/:id"
          element={<EmployeeDocumentsUpdate />}
        />

        <Route path="/emiratesidupdate/:id" element={<EmiratesIdUpdate />} />
        <Route
          path="/insurancecardupdate/:id"
          element={<InsuranceCardUpdate />}
        />
        <Route path="/accesscardupdate/:id" element={<AccessCardUpdate />} />
        <Route path="/employeedirectory/:id" element={<EmployeeDirectory />} />
        <Route path="/viewdirectory/:id" element={<ViewEmployeeInfo />} />
        <Route path="/exitmanagement" element={<ExitManagement />} />
        <Route
          path="/exitmanagementview/:id"
          element={<ExitManagementView />}
        />
        <Route path="/exitmanagementform" element={<ExitManagementForm />} />
        <Route path="/learn&develop" element={<LearnDevelop />} />
        <Route path="/leavemanagement" element={<LeaveManagement />} />
        <Route
          path="/totalleaverqttable"
          element={<TotalLeaveRequestTable />}
        />
        <Route
          path="/leaveapprovedtable"
          element={<TotalLeaveApprovedTable />}
        />
        <Route
          path="/pendingleaverqttable"
          element={<PendingLeaveRequestsTable />}
        />
        <Route path="/onboarding" element={<OnBoard />} />
        <Route path="/onboardaddemployee" element={<OnBoardAddEmployee />} />
        <Route path="/paymenthistory" element={<PaymentHistory />} />
        <Route path="/recruitment" element={<Recruitment />} />
        <Route path="/rolemanagement" element={<RoleManagement />} />
        <Route path="/createrole" element={<RoleManagementForm />} />
        <Route path="/updatepermissions/:id" element={<RoleManagementEdit />} />
        <Route path="/support" element={<Support />} />
        <Route path="/timeattendance" element={<TimeAttendance />} />
        <Route path="/timesettings" element={<TimeSettings />} />
        <Route
          path="/employeeleavemoredetails"
          element={<EmployeeLeaveMoreDetails />}
        />
        <Route
          path="/employeeleavehistory"
          element={<EmployeeLeaveHistoryTable />}
        />
        <Route path="/editleavedetails" element={<EditLeaveDetails />} />
        <Route path="/employeedirectory" element={<EmployeeDirectory />} />
      </Route>
    </Routes>
  );
};

export default Router;
