import { convertDateFormat } from "../../utils/Converters/convertDateFormat";
import { useReactToPrint } from "react-to-print";


export const usePrintHandler = (componentRef) => {
  return useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print { 
        @page { 
          size: A4 portrait; 
          margin: 5mm;
          marks: none;
        }
        body { 
          -webkit-print-color-adjust: exact; 
          margin: 0 !important;
        }
        .print-hide { 
          display: none !important; 
        }
      }
    `,
    removeAfterPrint: true,
  });
};


export const getEmployeeDetails = (data) => [
  {
    label1: "Employee Name",
    value1: data?.employee_name,
    label2: "Employee ID",
    value2: data?.employee_id,
  },
  {
    label1: "Designation",
    value1: data?.role,
    label2: "Date of Joining",
    value2: convertDateFormat(data?.joining_date),
  },
  {
    label1: "Worked Days",
    value1: `${data?.payslip?.worked_days} Days`,
    label2: "Department",
    value2: data?.department,
  },
];


export const getPayslipDetails = (data, netSalaryDetails) => ({
  earnings: [
    { label: "Basic", value: data?.payslip?.basic_salary },
    { label: "HRA", value: data?.payslip?.hra },
    { label: "Car Allowances", value: data?.payslip?.car_allowance },
    {
      label: "Transport Allowances",
      value: data?.payslip?.transport_allowance,
    },
    {
      label: "Others",
      value: data?.payslip?.others_addition,
      total: netSalaryDetails?.totalEarnings,
      remarks: data?.payslip?.addition_remarks,
    },
  ],
  deductions: [
    { label: "Unpaid Leaves", value: data?.payslip?.unpaid_leaves },
    { label: "Car", value: data?.payslip?.car_deduction },
    { label: "Fines", value: data?.payslip?.fines },
    { label: "Salik", value: data?.payslip?.salik },
    { label: "Telephone", value: data?.payslip?.telephone },
    { label: "Loan Taken", value: data?.payslip?.loan_taken },
    { label: "Salary Advance", value: data?.payslip?.salary_advance },
    {
      label: "Transport Extra Usage",
      value: data?.payslip?.transport_extra_usage,
    },
    {
      label: "Others",
      value: data?.payslip?.others_deduction,
      total: netSalaryDetails?.deductions,
      remarks: data?.payslip?.deduction_remarks,
    },
  ],
  additions: [
    { label: "Leave Encashment", value: data?.payslip?.leave_encashment },
    { label: "Overtime", value: data?.payslip?.overtime },
    { label: "Comp off", value: data?.payslip?.compo_off },
    { label: "Commission", value: data?.payslip?.commission },
    {
      label: "Others",
      value: data?.payslip?.other_allowance,
      total: netSalaryDetails?.totalAdditions,
    },
  ],
});
