import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { SalaryBulkUploadValidationSchema } from "../../utils/ValidationData/PayrollManagement/SalaryBulkUploadValidation";

const SalaryBulkUpload = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

    const initialValues = {
      
    companyName: "",
    department: "",
    salary_month: "",
    PayslipFile: null,
  };
 

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCompany();
  }, []);

  const getDepartments = useCallback(
    (company = selectedCompany) => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/companies/${company}/departments`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data?.data || [];
          setDepartmentList(data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    [selectedCompany]
  );

  const handleSubmit = (values) => {
    const formData = new FormData();
    
    
    formData.append("PayslipFile", values.PayslipFile); 
    formData.append("companyName", values.companyName);
    formData.append("department", values.department);
    formData.append("salary_month", values.salary_month);
  
    fetch("https://hrmsapi.ttf.ae/api/payroll/uploadPayslip", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      body: formData,
      redirect: "follow"
    })
      .then((response) => {
        if (!response.ok) {
          // Parse error response as JSON to get more details
          return response.json().then(errData => {
            throw new Error(errData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((result) => {
        alert("Upload successful!");
        navigate("/employeesalary");
      })
      .catch((error) => {
        console.error("Error uploading data:", error);
        alert("Error uploading file: " + error.message);
      });
  };

  const handleInputChange = (event, type, formik) => {
    const { name, value } = event.target;
    if (type === "company") {
      setSelectedCompany(value);
      formik.setFieldValue(name, value);
      getDepartments(value);
    } else if (type === "department") {
      setSelectedDepartment(value);
      setSelectedDepartmentId(value);
      formik.setFieldValue(name, value);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: SalaryBulkUploadValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/employeesalary")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Bulk Upload Employee Salary</h4>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} style={{ padding: "30px" }}>
        <div className="form-rows">
          <div>
            <label htmlFor="companyName">Company Name</label>
            <select
              name="companyName"
              onChange={(e) => handleInputChange(e, "company", formik)}
              onBlur={formik.handleBlur}
              value={selectedCompany}
            >
              <option value="">Select Company Name</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.company_name}
                </option>
              ))}
            </select>
            {formik.errors.companyName && formik.touched.companyName && (
              <div className="error-message">{formik.errors.companyName}</div>
            )}
          </div>

          <div>
            <label htmlFor="department">Department</label>
            <select
              name="department"
              onChange={(e) => handleInputChange(e, "department", formik)}
              onBlur={formik.handleBlur}
              value={selectedDepartment}
            >
              <option value="">Select Department</option>
              {departmentList.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.department_name}
                </option>
              ))}
            </select>
            {formik.errors.department && formik.touched.department && (
              <div className="error-message">{formik.errors.department}</div>
            )}
          </div>
        </div>

        <div className="form-rows">
          <div>
            <label htmlFor="salary_month">Month & Year</label>
            <input
              name="salary_month"
              type="month"
              id="salary_month"
              onChange={(e) => {
                formik.setFieldValue("salary_month", e.target.value);
              }}
              onBlur={formik.handleBlur}
              max={`${currentYear}-12`}
            />
            {formik.errors.salary_month && formik.touched.salary_month && (
              <div className="error-message">{formik.errors.salary_month}</div>
            )}
          </div>

          <div>
            <label htmlFor="PayslipFile">Upload Excel File</label>
            <input
              name="PayslipFile"
              className="custom-file-upload"
              type="file"
              id="PayslipFile"
              accept=".xls,.xlsx"
              onChange={(e) => {
                formik.setFieldValue("PayslipFile", e.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.errors.PayslipFile && formik.touched.PayslipFile && (
              <div className="error-message">{formik.errors.PayslipFile}</div>
            )}
          </div>
        </div>

        <div className="user-btn-section">
          <div style={{ marginTop: "1rem" }}>
            <button type="submit" className="form-btn-submit">
              Save & Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SalaryBulkUpload;