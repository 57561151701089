import { toTitleCase } from "../../utils/Converters/caseConverters";

const EmployeeDetailRow = ({ label1, value1, label2, value2 }) => {
  return (
    <tr>
      <td className="border border-gray-300 p-2 text-11px text-left text-title-black font-medium" style={{ width: '20%' }}>
        {label1}
      </td>
      <td className="border border-gray-300 p-2 text-11px text-left text-desc-gray font-medium normal-case first-letter:uppercase" style={{ width: '45%' }}>
        {toTitleCase(value1)}
      </td>
      <td className="border border-gray-300 p-2 text-11px text-left text-title-black font-medium" style={{ width: '20%' }}>
        {label2}
      </td>
      <td className="border border-gray-300 p-2 text-11px text-left text-desc-gray font-medium normal-case first-letter:uppercase" style={{ width: '15%' }}>
        {value2}
      </td>
    </tr>
  );
};

export default EmployeeDetailRow;
