import axios from "axios";
const accessToken = localStorage.getItem("accessToken");

export const getCompany = (setCompanyList) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      const data = response.data?.data || [];
      setCompanyList(data);
    })
    .catch((error) => console.log(error));
};

export const getDepartments = (selectedCompany, setDepartmentList) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/departments?company=${selectedCompany}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      const data = response.data?.data || [];
      setDepartmentList(data);
      return data;
    })
    .catch((error) => console.log(error));
};


export const getSalaryDataOnPageLoad = async (
  selectedCompanyId,
  currentMonth,
  currentYear,
  setEmployeeSalaryData
) => {
  // Validate inputs before making the API call
  if (!selectedCompanyId || !currentMonth || !currentYear) {
    console.error("Missing required parameters:", { selectedCompanyId, currentMonth, currentYear });
    setEmployeeSalaryData([]); // Set empty array as fallback
    return;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/payroll/payslipByCompanyAndDepartment?company_id=${selectedCompanyId}&month=${currentMonth}&year=${currentYear}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Response", response)

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error("API Error Response:", errorData);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const employeeDetails = data?.employeeDetails || [];
    setEmployeeSalaryData(employeeDetails);
    return employeeDetails;
  } catch (error) {
    console.error("Error fetching salary data:", error);
    setEmployeeSalaryData([]); // Set empty array on error
    throw error;
  }
};

export const getSalaryDataOnSearch = async (
  companyId,
  departmentId,
  salaryMonth,
  salaryYear,
  setEmployeeSalaryData
) => {
  try {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/payroll/payslipByCompanyAndDepartment`;
    const url = departmentId
      ? `${baseUrl}?company_id=${companyId}&department_id=${departmentId}&month=${salaryMonth}&year=${salaryYear}`
      : `${baseUrl}?company_id=${companyId}&month=${salaryMonth}&year=${salaryYear}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error("Error Response:", errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const employeeDetails = data?.employeeDetails || [];
    console.log("getSalaryData ", employeeDetails);
    setEmployeeSalaryData(employeeDetails);
    return employeeDetails;
  } catch (error) {
    console.error("Error fetching salary data:", error);
    throw error;
  }
};
