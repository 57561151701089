import "../../styles/payroll.css";

const SalarySearchTable = ({
  setSelectedCompany,
  setSalaryMonth,
  setSalaryYear,
  setCompanyId,
  setDepartmentId,
  companyList,
  departmentList = [],
  handleSearch,
}) => {
  const handleCompanyChange = (event) => {
    const selectedCompanyName = event.target.value;
    const selectedCompany = companyList.find(
      (company) => company.company_name === selectedCompanyName
    );
    const selectedCompanyId = selectedCompany ? selectedCompany.id : null;
    setCompanyId(selectedCompanyId);
    setSelectedCompany(selectedCompanyName);
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartmentName = event.target.value;
    const selectedDepartment = departmentList.find(
      (department) => department.department_name === selectedDepartmentName
    );
    const selectedDepartmentId = selectedDepartment
      ? selectedDepartment.id
      : null;
    setDepartmentId(selectedDepartmentId);
  };

  const handleMonthYearChange = (event) => {
    const selectedDate = event.target.value;
    const [year, month] = selectedDate.split("-");

    const date = new Date(`${year}-${month}-01`);
    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(date);
    
    setSalaryMonth(monthName);
    setSalaryYear(year);
    
  };

  return (
    <div className="salary__search-table">
      <div className="salary__search-item">
        <label htmlFor="company">All Companies</label>
        <select
          name="company"
          id="company"
          // value={selectedCompany}
          onChange={(event) => handleCompanyChange(event)}
        >
          <option value="">Select Company</option>
          {companyList.map((company) => (
            <option key={company.id} value={company.company_name}>
              {company.company_name}
            </option>
          ))}
        </select>
      </div>
      <div className="salary__search-item">
        <label htmlFor="department">All Departments</label>
        <select
          name="department"
          id="department"
          onChange={(event) => handleDepartmentChange(event)}
        >
          <option value="">Select the Department</option>
          {departmentList.map((department) => (
            <option key={department.id} value={department.department_name}>
              {department.department_name}
            </option>
          ))}
        </select>
      </div>
      <div className="salary__search-item">
        <label htmlFor="salary_date">Month & Year</label>
        <input
          type="month"
          name="salary_date"
          onChange={(event) => handleMonthYearChange(event)}
        />
      </div>
      <div className="salary__search-icon">
        <div className="search__table-icon" onClick={handleSearch}>
          <i className="ri-search-line"></i>
        </div>
      </div>
    </div>
  );
};

export default SalarySearchTable;
